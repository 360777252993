import React, { useId } from "react";
import { useFormik } from "formik";
import "../assets/styles/VoteForm.css";
import { connect } from "react-redux";
import { SWAL_NOTIFICATION, SWAL_QUESTION, VOTE_FAILED } from "../types";
import { requests } from "../axios";
import { useNavigate } from "react-router-dom";

const VoteForm = ({
  assemblyApartments,
  voteState,
  setVoteState,
  requestState,
  setRequestState,
  redirect,
}) => {
  const id = useId();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      answer: "",
    },
    onSubmit: async (values) => {
      try {
        const answerSelected = voteState.data.answers.filter(
          (answer) => answer.answerId === values.answer
        )[0].answerDescription;
        const swalAnswer = await SWAL_QUESTION.fire({
          icon: "question",
          html: `<p>Seleccionó la opción <b>"${answerSelected}"</b>.<br/> ¿Está seguro de su respuesta?</p>`,
          showDenyButton: true,
          confirmButtonText: "Sí",
          denyButtonText: "No",
        });
        if (swalAnswer.isConfirmed) {
          setRequestState({ loading: true, error: null });
          // eslint-disable-next-line
          const votesRegistered = await Promise.all(
            assemblyApartments.map((apartment) => {
              return (
                apartment.coefficient !== 0 &&
                requests.assembly.register({
                  jointId: voteState.data.jointId,
                  apartmentId: apartment.apartmentId,
                  conceptId: voteState.data.conceptId,
                  voteId: values.answer,
                })
              );
            })
          );
          if (votesRegistered) {
            setVoteState({ ...voteState, isVoted: true });
            setRequestState({ ...requestState, loading: false });
            SWAL_NOTIFICATION.fire({
              icon: "success",
              title: "Votación exitosa.",
            });
            redirect && navigate("/LaunchPage");
          }
        }
      } catch (error) {
        if (error.response.status === 409) {
          SWAL_NOTIFICATION.fire({
            icon: "error",
            title: "Solo se puede votar una vez.",
          });
          setRequestState({
            ...requestState,
            loading: false,
          });
          redirect && navigate("/LaunchPage");
        } else {
          setRequestState({
            loading: false,
            error: VOTE_FAILED,
          });
        }
      }
    },
  });

  return (
    <>
      {voteState.isVoted ? (
        <h1 className="completion-message" data-testid="completionMessage">
          Gracias por su votación.
        </h1>
      ) : (
        <section className="userform" role="form">
          <form
            className="square-form vote-grid"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="vote-title">Votación</h2>
            {voteState.data && (
              <h4 className="concept-description">
                {voteState.data.conceptDescription}
              </h4>
            )}
            <div className="options-block">
              {voteState.data?.answers.map((answer, index) => {
                return (
                  <div className="option" key={`${id}-${index}`}>
                    <input
                      type="radio"
                      id={answer.answerId}
                      name="option"
                      value={answer.answerId}
                      onChange={() => {
                        formik.setFieldValue("answer", answer.answerId);
                      }}
                    />
                    <label htmlFor={answer.answerId}>
                      {answer.answerDescription}
                    </label>
                  </div>
                );
              })}
            </div>
            {requestState.error && (
              <h6 className="bad-request error-message" role="alert">
                {requestState.error}
              </h6>
            )}
            <input
              type="submit"
              className="btn-block btn scaf-accept-btn"
              value="Votar"
            />
          </form>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    assemblyApartments: state.assemblyApartments,
  };
};

export default connect(mapStateToProps, null)(VoteForm);
