import React, { useId, useState } from "react";
import { connect } from "react-redux";

const JointsBillingApartmentInput = ({
  formik,
  billingJoints,
  billingApartments,
  handleOnChange,
}) => {
  const id = useId();

  const [apartmentState, setApartmentState] = useState({
    apartments: {},
  });

  const handleApartments = () => {
    formik.setFieldValue("apartmentId", "");
    const jointId = document.getElementById("joint").value;
    formik.setFieldValue("jointId", jointId);
    const apartments = billingApartments.filter(
      (apartment) => apartment.jointId === parseInt(jointId)
    );
    setApartmentState({ apartments: apartments });
  };

  const defaultOnChange = () => {
    const apartmentId = document.getElementById("apartment").value;
    formik.setFieldValue("apartmentId", apartmentId);
  };

  const options =
    billingJoints.length &&
    billingJoints.map((joint, index) => {
      return (
        <option value={joint.jointId} key={`${id}-${index}`}>
          {joint.jointName}
        </option>
      );
    });

  return (
    <>
      {billingApartments.length === 1 ? (
        <>
          <input
            className="userform-input-text joint-input owner-form-input"
            type="text"
            data-testid="jointInput"
            value={billingJoints[0].jointName}
            disabled
          />
          <input
            className="userform-input-text apartment-input owner-form-input"
            type="text"
            data-testid="apartmentInput"
            value={billingApartments[0].apartmentId}
            disabled
          />
        </>
      ) : (
        <>
          <select
            className="joint-input owner-form-joint-select userform-select"
            id="joint"
            name="joint"
            data-testid="jointInputSelect"
            value={formik.values.jointId}
            onChange={handleApartments}
            required
          >
            <option value="">Seleccione un conjunto</option>
            {options}
          </select>
          <select
            className="apartment-input userform-select"
            id="apartment"
            name="apartment"
            data-testid="apartmentInputSelect"
            value={formik.values.apartmentId}
            onChange={handleOnChange || defaultOnChange}
            required
          >
            <option value="">Seleccione un inmueble</option>
            {apartmentState.apartments.length &&
              apartmentState.apartments.map((apartment, index) => {
                return (
                  <option value={apartment.apartmentId} key={`${id}-${index}`}>
                    {apartment.apartmentId}
                  </option>
                );
              })}
          </select>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    billingJoints: state.billingJoints,
    billingApartments: state.billingApartments,
  };
};

export default connect(mapStateToProps, null)(JointsBillingApartmentInput);
