import React, { useState, useEffect, useId } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import "../../assets/styles/LaunchPage.css";
import { VOTES_REGISTERED_FAILED } from "../../types";
import { requests } from "../../axios";

const LaunchPage = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [votesRegistered, setVotesRegistered] = useState({});

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(intervalId);
  });

  const fetchData = async () => {
    try {
      const conceptsResponse =
        await requests.joints.getAssemblyConceptsByJointId(
          props.assemblyApartments[0].jointId
        );
      const votesRegisteredResponse =
        await requests.assembly.getVotesRegistered({
          jointId: props.assemblyApartments[0].jointId,
          apartmentId: props.assemblyApartments[0].apartmentId,
        });
      setVotesHistory(conceptsResponse.data, votesRegisteredResponse.data);
      setRequestState({
        ...requestState,
        loading: false,
      });
    } catch (error) {
      setRequestState({
        loading: false,
        error: VOTES_REGISTERED_FAILED,
      });
      console.log(error);
    }
  };

  const setVotesHistory = async (concepts, votesRegistered) => {
    const votes = concepts.map((concept) => {
      const isVoted = votesRegistered.find(
        (vote) => vote.conceptId === concept.conceptId
      );
      const voted = concept.conceptId === 1 ? "Registrado" : "Votado";
      const notVoted = concept.conceptId === 1 ? "Sin registrar" : "No votado";
      return {
        conceptId:
          concept.conceptId === 1 ? "" : `VOTACIÓN ${concept.conceptId - 1}`,
        conceptDescription: concept.conceptDescription,
        isVoted: isVoted ? voted : notVoted,
      };
    });
    setVotesRegistered(votes);
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <div className="launch-page-grid">
            <table
              className="apartments-registered-table"
              data-testid="apartmentsRegisteredTable"
            >
              <thead>
                <tr>
                  <th colSpan="3">
                    <h3 className="table-title">{`Inmuebles Registrados. Coeficiente: ${props.assemblyApartments
                      .reduce(
                        (sum, apartment) => sum + apartment.coefficient,
                        0
                      )
                      .toFixed(4)}`}</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.assemblyApartments.map((apartment, index) => {
                  return (
                    <tr key={`${id}-${index}`}>
                      <td>{apartment.apartmentId}</td>
                      <td>{apartment.owner}</td>
                      <td>{apartment.coefficient.toFixed(4)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table
              className="votes-history-table"
              data-testid="votesHistoryTable"
            >
              <thead>
                <tr>
                  <th colSpan="3">
                    <h3 className="table-title">Historial de Votaciones</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                {votesRegistered &&
                  votesRegistered.map((vote, index) => {
                    return (
                      <tr key={`${id}-${index}`}>
                        <td>{vote.conceptId}</td>
                        <td>{vote.conceptDescription}</td>
                        <td>{vote.isVoted}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <a
              className="btn scaf-accept-btn zoom-btn"
              href={`https://us02web.zoom.us/j/${props.zoomMeeting.zoomId}?pwd=${props.zoomMeeting.zoomPassword}`}
              target="_blank"
              rel="noreferrer"
            >
              Ingresar a la reunión
            </a>
            <Link
              className="btn scaf-accept-btn vote-btn"
              to={`/Vote/${props.assemblyApartments[0].jointId}`}
            >
              Votar
            </Link>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyApartments: state.assemblyApartments,
    assemblyConcepts: state.assemblyConcepts,
    zoomMeeting: state.zoomMeeting,
  };
};

export default connect(mapStateToProps, null)(LaunchPage);
