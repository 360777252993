import React, { useId } from "react";
import { connect } from "react-redux";
import { getAssemblyConceptsRequest } from "../actions";
import { requests } from "../axios";
import {
  CONCEPTS_NOT_FOUND,
  CONCEPT_INPUT_DEFAULT_OPTION,
  CONCEPT_INPUT_LABEL,
  JOINT_INPUT_DEFAULT_OPTION,
  JOINT_INPUT_LABEL,
} from "../types";

const JointsConceptInput = ({
  assemblyJoints,
  assemblyConcepts,
  getAssemblyConceptsRequest,
  showTitles,
  formik,
  setRequestState,
  handleOnChange,
}) => {
  const id = useId();

  const handleConcepts = async () => {
    try {
      setRequestState({
        error: null,
      });
      const jointId = document.getElementById("joint").value;
      formik.setFieldValue("jointId", jointId);
      formik.setFieldValue("conceptId", "");
      const response =
        jointId &&
        (await requests.joints.getAssemblyConceptsByJointId(jointId));
      getAssemblyConceptsRequest(response.data || {});
    } catch (error) {
      setRequestState({
        error: CONCEPTS_NOT_FOUND,
      });
    }
  };

  const defaultOnChange = () => {
    const conceptId = document.getElementById("concept").value;
    formik.setFieldValue("conceptId", conceptId);
  };

  const options =
    assemblyJoints.length &&
    assemblyJoints.map((assembly, index) => {
      return (
        <option value={assembly.joint.jointId} key={`${id}-${index}`}>
          {assembly.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {showTitles && <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>}
      <select
        className="joint-input userform-select assembly-form-select"
        id="joint"
        name="joint"
        data-testid="jointInput"
        value={formik.values.jointId}
        onChange={handleConcepts}
      >
        <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
        {options}
      </select>
      {showTitles && <h5 className="concept-label">{CONCEPT_INPUT_LABEL}</h5>}
      <select
        className="concept-input userform-select assembly-form-select"
        id="concept"
        name="concept"
        data-testid="conceptInput"
        value={formik.values.conceptId}
        onChange={handleOnChange || defaultOnChange}
        required
      >
        <option value="">{CONCEPT_INPUT_DEFAULT_OPTION}</option>
        {assemblyConcepts.length &&
          assemblyConcepts.map((concept, index) => {
            return (
              <option value={concept.conceptId} key={`${id}-${index}`}>
                {concept.conceptDescription}
              </option>
            );
          })}
      </select>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    assemblyJoints: state.assemblyJoints,
    assemblyConcepts: state.assemblyConcepts,
  };
};

const mapDispatchToProps = {
  getAssemblyConceptsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(JointsConceptInput);
