import React, { useState } from "react";
import { connect } from "react-redux";

import "../assets/styles/LogIn.css";
import { logInRequest } from "../actions";
import { LOGIN_FAILED, TOKEN, USERDATA } from "../types";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { auth, requests } from "../axios";
import { setStorage } from "../utils";

const LogIn = (props) => {
  const navigate = useNavigate();

  const [requestState, setRequestState] = useState({
    loading: false,
    error: null,
  });

  const getUser = async (username) => {
    const response = await requests.users.getByUsername(username);
    delete response.data["password"];
    setStorage(USERDATA, JSON.stringify(response.data));
    props.logInRequest(response.data);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: async (values) => {
      try {
        setRequestState({ loading: true, error: null });
        const response = await auth({
          username: values.username,
          password: values.password,
        });
        setStorage(TOKEN, `Bearer ${response.data.jwt}`);
        await getUser(values.username);
        setRequestState({ ...requestState, loading: false });
        navigate("/UserHome");
      } catch (error) {
        setRequestState({ loading: false, error: LOGIN_FAILED });
        console.log(error.response.status);
      }
    },
  });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <section className="userform" role="form">
          <form
            className="square-form login-grid"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="login-title">Iniciar sesión</h2>
            <div className="username-block">
              <label htmlFor="username">Nombre de Usuario</label>
              <input
                className="userform-input-text"
                type="text"
                id="username"
                name="username"
                autoComplete="username"
                {...formik.getFieldProps("username")}
                autoFocus
                required
              />
            </div>
            <div className="password-block">
              <label htmlFor="password">Contraseña</label>
              <input
                className="userform-input-text"
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                {...formik.getFieldProps("password")}
                required
              />
            </div>
            {requestState.error && (
              <h6 className="failed-login error-message" role="alert">
                {requestState.error}
              </h6>
            )}
            <input
              type="submit"
              className="btn-block btn scaf-accept-btn"
              value="Aceptar"
            />
          </form>
        </section>
      )}
    </>
  );
};

const mapDispatchToProps = {
  logInRequest,
};

export default connect(null, mapDispatchToProps)(LogIn);
