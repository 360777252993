import React, { useState, useEffect, useId } from "react";
import { connect } from "react-redux";
import { getBillingJointRequest } from "../../actions";
import Loading from "../../components/Loading";
import { downloadReport, requests } from "../../axios";
import { getStorage } from "../../utils";
import {
  JOINTS_NOT_FOUND,
  JOINTS_APARTMENTS_NOT_FOUND,
  USERDATA,
  JOINT_INPUT_LABEL,
  JOINT_INPUT_DEFAULT_OPTION,
  APARTMENT_LABEL,
  APARTMENT_INPUT_DEFAULT_OPTION,
  REPORT_GENERATION_FAILED,
} from "../../types";
import "../../assets/styles/StatementOfAccount.css";
import { useFormik } from "formik";

const StatementOfAccount = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [apartmentState, setApartmentState] = useState({
    apartments: {},
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.billingJoints.length) {
        const response = await requests.users.getUsersBillingJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getBillingJointRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const handleApartments = async () => {
    setApartmentState({ apartments: {} });
    setRequestState({ ...requestState, error: null });
    formik.setFieldValue("apartmentId", "");
    const jointId = document.getElementById("joint").value;
    formik.setFieldValue("jointId", jointId);

    try {
      const response = await requests.joints.getBillingApartments(jointId);
      setApartmentState({ apartments: response.data });
    } catch (error) {
      console.log(error);
      setRequestState({
        ...requestState,
        error: JOINTS_APARTMENTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      apartmentId: "",
    },
    onSubmit: async (values) => {
      setRequestState({ ...requestState, loading: true });
      try {
        const response = await requests.reports.getAccountBalanceReport(
          values.jointId,
          values.apartmentId
        );
        downloadReport(
          response.data,
          `Estado de Cuenta Inmueble ${values.apartmentId}`,
          "pdf"
        );
        setRequestState({ ...requestState, loading: false });
      } catch (error) {
        console.log(error);
        setRequestState({ loading: false, error: REPORT_GENERATION_FAILED });
      }
    },
  });

  const options =
    props.billingJoints.length &&
    props.billingJoints.map((billing, index) => {
      return (
        <option value={billing.joint.jointId} key={`${id}-${index}`}>
          {billing.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <section className="userform" role="form">
          <form
            className="square-form statement-account-grid"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="statement-account-title">Estados de Cuenta</h2>
            <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>
            <select
              className="joint-input userform-select"
              id="joint"
              name="joint"
              data-testid="jointInput"
              value={formik.values.jointId}
              onChange={handleApartments}
              required
            >
              <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
              {options}
            </select>
            <h5 className="owner-label">{APARTMENT_LABEL}</h5>
            <select
              className="owner-input userform-select"
              id="apartment"
              name="apartment"
              data-testid="apartmentInput"
              {...formik.getFieldProps("apartmentId")}
              required
            >
              <option value="">{APARTMENT_INPUT_DEFAULT_OPTION}</option>
              {apartmentState.apartments.length &&
                apartmentState.apartments.map((apartment, index) => {
                  return (
                    <option
                      value={apartment.apartmentId}
                      key={`${id}-${index}`}
                    >
                      {`${apartment.apartmentId} - ${apartment.owner}`}
                    </option>
                  );
                })}
            </select>
            {requestState.error && (
              <h6 className="bad-request error-message" role="alert">
                {requestState.error}
              </h6>
            )}
            <input
              type="submit"
              className="btn-block btn scaf-accept-btn"
              value="Aceptar"
            ></input>
          </form>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    billingJoints: state.billingJoints,
  };
};

const mapDispatchToProps = {
  getBillingJointRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementOfAccount);
