import { TOKEN } from "../types";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = 30000;

export const axiosServer = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

const setAuthorizationHeader = () => {
  axiosServer.defaults.headers.common["Authorization"] =
    sessionStorage.getItem(TOKEN);
};

export const auth = async (loginData) => {
  return axios.post("/auth", loginData);
};

export const requests = {
  users: {
    async getByUsername(username) {
      setAuthorizationHeader();
      return axiosServer.get(`/users/${username}`);
    },
    async getUsersAssemblyJoints(userId) {
      setAuthorizationHeader();
      return axiosServer.get(`/users/${userId}/assemblyjoints`);
    },
    async getUsersBillingJoints(userId) {
      setAuthorizationHeader();
      return axiosServer.get(`/users/${userId}/billingjoints`);
    },
    async getUsersBillingApartments(userId) {
      setAuthorizationHeader();
      return axiosServer.get(`/users/${userId}/billingapartments`);
    },
    async getUsersAssemblyApartments(userId) {
      // setAuthorizationHeader();
      return axiosServer.get(`/users/${userId}/assemblyapartments`);
    },
  },
  joints: {
    async getAssemblyConceptsByJointId(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/joints/${jointId}/assemblyconcepts`);
    },
    async getJointById(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/joints/${jointId}`);
    },
    async getBillingApartments(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/joints/${jointId}/billingapartments`);
    },
    async getAssemblyApartments(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/joints/${jointId}/assemblyapartments`);
    },
    async getAssemblyConceptByJointIdAndConceptId(assemblyConceptData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/joints/${assemblyConceptData.jointId}/assemblyconcepts/${assemblyConceptData.conceptId}`
      );
    },
    async getActiveConcept(jointId) {
      // setAuthorizationHeader();
      return axiosServer.get(`/joints/${jointId}/activeassemblyconcept`);
    },
  },
  assemblyApartments: {
    async assignUserId(apartmentData) {
      setAuthorizationHeader();
      return axiosServer.patch("/assemblyapartments", apartmentData);
    },
  },
  assembly: {
    async getApartmentsRegistered(conceptData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/assembly/${conceptData.conceptId}/joints/${conceptData.jointId}`
      );
    },
    async getVotesRegistered(apartmentData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/assembly/joints/${apartmentData.jointId}/assemblyapartments/${apartmentData.apartmentId}`
      );
    },
    async register(apartmentData) {
      // setAuthorizationHeader();
      return axiosServer.post("/assembly", apartmentData);
    },

    async sectorialRegister(apartmentData) {
      setAuthorizationHeader();
      return axiosServer.post("/assembly/sector", apartmentData);
    },
    async getCoefficient(answerData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/assembly/${answerData.concept}/joints/${answerData.jointId}/quorum?voteId=${answerData.voteId}`
      );
    },
    async getTotalApartments(answerData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/assembly/${answerData.concept}/joints/${answerData.jointId}/apartmentsquantity?voteId=${answerData.voteId}`
      );
    },
    async deleteRegisteredApartment(assemblyData) {
      setAuthorizationHeader();
      return axiosServer.delete(
        `/assembly/${assemblyData.conceptId}/joints/${assemblyData.jointId}?apartmentId=${assemblyData.apartmentId}`
      );
    },
    async deleteAllApartmentsRegistered(assemblyData) {
      setAuthorizationHeader();
      return axiosServer.delete(
        `/assembly/${assemblyData.conceptId}/joints/${assemblyData.jointId}`
      );
    },
  },
  assemblyConcepts: {
    async updateConceptStatus(conceptData) {
      setAuthorizationHeader();
      return axiosServer.patch("/assemblyconcepts", conceptData);
    },
    async deleteConcept(conceptData) {
      setAuthorizationHeader();
      return axiosServer.delete("/assemblyconcepts", {
        data: conceptData,
      });
    },
    async saveConcept(conceptData) {
      setAuthorizationHeader();
      return axiosServer.post("/assemblyconcepts", conceptData);
    },
  },
  movements: {
    async getApartmentsBills(jointId, apartmentId) {
      setAuthorizationHeader();
      return axiosServer.get(`/movements/${jointId}/apartments/${apartmentId}`);
    },
    async getYearsByJointId(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/movements/${jointId}/years`);
    },
    async getMonthsByJointIdAndYear(jointId, year) {
      setAuthorizationHeader();
      return axiosServer.get(`/movements/${jointId}/years/${year}`);
    },
  },
  cashReceipts: {
    async getApartmentsCashReceipts(jointId, apartmentId) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/cashreceipts/${jointId}/apartments/${apartmentId}`
      );
    },
    async getYearsByJointId(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/cashreceipts/${jointId}/years`);
    },
    async getMonthsByJointIdAndYear(jointId, year) {
      setAuthorizationHeader();
      return axiosServer.get(`/cashreceipts/${jointId}/years/${year}`);
    },
  },
  reports: {
    async getAssistanceReport(jointId, xlsx) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/reports/assistance/${jointId}${xlsx ? "?xlsx=true" : ""}`,
        { responseType: "arraybuffer" }
      );
    },
    async getVoteReport(voteData, xlsx) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/reports/vote/${voteData.jointId}?conceptId=${voteData.conceptId}${
          xlsx ? "&xlsx=true" : ""
        }`,
        { responseType: "arraybuffer" }
      );
    },
    async getBalanceByAgeReport(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/reports/balancebyage/${jointId}`, {
        responseType: "arraybuffer",
      });
    },
    async getMonthlyPaymentsReport(reportData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/reports/monthspayments/${reportData.jointId}?month=${reportData.month}&year=${reportData.year}`,
        { responseType: "arraybuffer" }
      );
    },
    async getCashReceiptReport(jointId, cashReceipt) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/reports/cashreceipt/${cashReceipt}?jointId=${jointId}`,
        { responseType: "arraybuffer" }
      );
    },
    async getBillsRelationshipReport(reportData) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/reports/billsrelationship/${reportData.jointId}?month=${reportData.month}&year=${reportData.year}`,
        { responseType: "arraybuffer" }
      );
    },
    async getAccountBalanceReport(jointId, apartmentId) {
      setAuthorizationHeader();
      return axiosServer.get(
        `/reports/accountbalance/${apartmentId}/joint/${jointId}`,
        { responseType: "arraybuffer" }
      );
    },
    async getDebtorsReport(jointId) {
      setAuthorizationHeader();
      return axiosServer.get(`/reports/debtors/${jointId}`, {
        responseType: "arraybuffer",
      });
    },
  },
};

export const downloadReport = (report, fileName, format) => {
  const url = window.URL.createObjectURL(new Blob([report]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${format}`);
  document.body.appendChild(link);
  link.click();
};
