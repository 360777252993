import React from "react";

import NavItem from "./NavItem";
import "../assets/styles/NavItem.css";

const homeNavBar = (
  <>
    <li className="nav-item" role="menuitem">
      <a className="nav-link" href="#whoWeAre">
        Quienes somos
      </a>
    </li>
    <li className="nav-item" role="menuitem">
      <a className="nav-link" href="#ourServices">
        Nuestros servicios
      </a>
    </li>
    <li className="nav-item" role="menuitem">
      <a className="nav-link" href="#clients">
        Clientes
      </a>
    </li>
  </>
);

const reportList = (
  <li>
    <span className="nav-item nav-link" role="menuitem">
      Informes
    </span>
    <ol className="nav-sublist" data-testid="reportList">
      <NavItem reference="/BillsRelationship" text="Relación de facturas" />
      <NavItem reference="/BalanceByAge" text="Cartera por edades" />
      <NavItem reference="/StatementOfAccount" text="Estados de cuenta" />
      <NavItem reference="/MonthlyPayments" text="Recaudos del mes" />
      <NavItem reference="/Debtors" text="Morosos" />
    </ol>
  </li>
);

const queryList = (isGrouped) =>
  isGrouped ? (
    <li>
      <span className="nav-item nav-link" role="menuitem">
        Consultas
      </span>
      <ol className="nav-sublist" data-testid="queryList">
        <NavItem reference="/StatementOfAccountOwner" text="Estado de cuenta" />
        <NavItem reference="/Bills" text="Cuentas de cobro" />
        <NavItem reference="/CashReceipts" text="Recibos de caja" />
      </ol>
    </li>
  ) : (
    <>
      <NavItem reference="/StatementOfAccountOwner" text="Estado de cuenta" />
      <NavItem reference="/Bills" text="Cuentas de cobro" />
      <NavItem reference="/CashReceipts" text="Recibos de caja" />
    </>
  );

const assemblyList = (isGrouped) =>
  isGrouped ? (
    <li>
      <span className="nav-item nav-link" role="menuitem">
        Asambleas
      </span>
      <ol className="nav-sublist" data-testid="assemblyList">
        <NavItem reference="/Quorum" text="Quorum" />
        <NavItem reference="/RegisterQR" text="Registro de asistencia" />
        <NavItem reference="/GenerateQuestion" text="Generar pregunta" />
        <NavItem reference="/ReverseRegister" text="Reversar registro" />
        <NavItem reference="/AssemblyPanel" text="Panel de control" />
        <NavItem reference="/AssemblyReporter" text="Generar informes" />
      </ol>
    </li>
  ) : (
    <>
      <NavItem reference="/Quorum" text="Quorum" />
      <NavItem reference="/RegisterQR" text="Registrar" />
      <NavItem reference="/ReverseRegister" text="Reversar" />
      <NavItem reference="/GenerateQuestion" text="Generar pregunta" />
      <NavItem reference="/AssemblyPanel" text="Panel de control" />
    </>
  );

export const typeNavBar = {
  Home: homeNavBar,
  Scaf: (
    <>
      {reportList}
      {queryList(true)}
      {assemblyList(true)}
    </>
  ),
  Admin: reportList,
  Owner: queryList(false),
  Assembly: assemblyList(false),
};

export default typeNavBar;
