import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import { getAssemblyJointsRequest } from "../../actions";
import { JOINTS_NOT_FOUND, USERDATA } from "../../types";
import "../../assets/styles/Quorum.css";
import { getStorage } from "../../utils";
import { requests } from "../../axios";
import { useFormik } from "formik";
import JointsConceptInput from "../../components/JointsConceptInput";
import QuorumResults from "../../components/QuorumResults";

const Quorum = (props) => {
  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [conceptState, setConceptState] = useState({
    data: {},
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.assemblyJoints.length) {
        const response = await requests.users.getUsersAssemblyJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getAssemblyJointsRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const handleAnswers = async () => {
    const conceptId = document.getElementById("concept").value;
    formik.setFieldValue("conceptId", conceptId);
    const conceptData = props.assemblyConcepts.filter(
      (concept) => concept.conceptId === parseInt(conceptId)
    );
    setConceptState({ data: conceptData });
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      conceptId: "",
    },
  });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <div className="square-form quorum-grid">
              <JointsConceptInput
                formik={formik}
                setRequestState={setRequestState}
                handleOnChange={handleAnswers}
              />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
            </div>
          </section>
          {(conceptState.data.length && (
            <QuorumResults concept={conceptState.data} />
          )) || <></>}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyJoints: state.assemblyJoints,
    assemblyConcepts: state.assemblyConcepts,
  };
};

const mapDispatchToProps = {
  getAssemblyJointsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Quorum);
