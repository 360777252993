import React from "react";

import ServiceCard from "../components/ServiceCard";
import HeroImage from "../assets/static/HeroImage.jpg";
import MeetingImage from "../assets/static/MeetingImage.png";
import MeetingImage2x from "../assets/static/MeetingImage@2x.png";
import VirtualMeetingImage from "../assets/static/VirtualMeetingImage.png";
import VirtualMeetingImage2x from "../assets/static/VirtualMeetingImage@2x.png";
import BillingImage from "../assets/static/BillingImage.png";
import BillingImage2x from "../assets/static/BillingImage@2x.png";
import CityPlazaLogo from "../assets/static/CityPlazaLogo.png";
import DelEsteLogo from "../assets/static/DelEsteLogo.png";
import MayorcaLogo from "../assets/static/MayorcaLogo.png";
import NuestroUrabaLogo from "../assets/static/NuestroUrabaLogo.png";
import NuevoConquistadoresBorealLogo from "../assets/static/NuevoConquistadoresBorealLogo.png";
import PISLogo from "../assets/static/PISLogo.png";
import PlacesLogo from "../assets/static/PlacesLogo.svg";
import PremiumPlazaLogo from "../assets/static/PremiumPlazaLogo.png";
import PuntoClaveLogo from "../assets/static/PuntoClaveLogo.png";
import SanNicolasLogo from "../assets/static/SanNicolasLogo.png";
import "../assets/styles/Home.css";

const Home = () => {
  return (
    <>
      <section className="hero">
        <figure className="hero-image">
          <img src={HeroImage} title="Hero" alt="Hero SCAF" />
        </figure>
      </section>
      <section className="who-we-are" id="whoWeAre" role="contentinfo">
        <h2 className="title who-we-are-title">Quienes Somos</h2>
        <p className="who-we-are-description" data-testid="history">
          A comienzos del año 1998, animados por las necesidades de la
          copropiedad donde vivíamos, decidimos iniciar el desarrollo de un
          software para la gestión de la facturación de las cuotas de
          administración de una forma clara, ágil y organizada facilitando las
          revisiones de la contabilidad, revisoría fiscal y auditorías.
          <br />
          <br />
          Al cabo de diez años, con la ayuda de algunos clientes tuvimos la
          necesidad de mejorar el proceso de registro de asistencia y votaciones
          por coeficiente en la asamblea de copropietarios para brindar agilidad
          y transparencia en las gestión de las mismas.
          <br />
          <br />
          Lo anterior nos impulsó a complementar el software de facturación con
          un nuevo módulo utilizando la herramienta de códigos de barra para
          ofrecerle a las copropiedades un servicio innovador. De esta forma
          nace <span className="scaf">SCAF</span> (Sistemas de Control de
          Asambleas y Facturación).
        </p>
        <h3 className="who-we-are-mision-title">Misión</h3>
        <p className="who-we-are-mision-description" data-testid="mision">
          Contribuir al bienestar, agilidad y seguridad de nuestros clientes, a
          través de la prestación de servicios integrales de facturación y
          control de asambleas, dirigidos principalmente a la propiedad
          horizontal, para ayudar en la gestión  de los administradores y su
          equipo de trabajo, convirtiéndonos en socios estratégicos.
        </p>
        <h3 className="who-we-are-vision-title">Visión</h3>
        <p className="who-we-are-vision-description" data-testid="vision">
          Ser en el 2028 la mejor empresa prestadora de servicios a las
          copropiedades y centros comerciales del Área Metropolitana,  con altos
          atributos de calidad, líder en servicios, logrando el reconocimiento
          de todos nuestros clientes.
        </p>
      </section>
      <section className="services" id="ourServices" role="contentinfo">
        <h2 className="title services-title">Nuestros Servicios</h2>
        <p className="services-description">
          Queremos ayudarle en su labor de administración de propiedad
          horizontal ofreciéndole una alianza estratégica, con calidad y
          eficiencia en la facturación de las cuotas de administración así como
          en la gestión y desarrollo de las asambleas.
        </p>
        <ServiceCard
          className="service-one"
          image={VirtualMeetingImage}
          image2x={`${VirtualMeetingImage}, ${VirtualMeetingImage2x} 2x`}
          title="Asambleas Virtuales"
        >
          <p className="article-description" data-testid="virtualMeeting">
            Calidad y eficiencia en el control de las asambleas ordinarias y
            extraordinarias mediante una plataforma web. Brindamos seguridad,
            tranquilidad y trasparencia en la toma de decisiones. Nuestro
            servicio se caracteriza por:
            <br />
            <br />
          </p>
          <ul className="article-list" data-testid="virtualMeeting">
            <li>
              Control del quórum para el inicio de la asamblea, teniendo en
              cuenta los coeficientes
            </li>
            <li>
              Control de las votaciones teniendo en cuenta los coeficientes
            </li>
            <li>
              Informes de registro del quórum y de las votaciones, teniendo en
              cuenta fecha y hora
            </li>
            <li>Usuario y contraseña únicos para cada propietario</li>
            <li>
              Envío de correo electrónico a cada propietario con las
              instrucciones y enlaces de ingreso. Además de su usuario y
              contraseña
            </li>
            <li>
              Grabación de la asamblea para que la administración realice el
              acta
            </li>
          </ul>
          <p className="article-description" data-testid="virtualMeeting">
            Para realizar la asamblea virtual es necesario contar con:
          </p>
          <ul className="article-list" data-testid="virtualMeeting">
            <li>Computador con sistema operativo Windows 7 o superior</li>
            <li>Navegador web Chrome</li>
            <li>
              Acceso internet con buen ancho de banda para evitar
              interrumpciones de la teleconferencia. Se recomienda mínimo 10
              megas de ancho de banda
            </li>
            <li>
              Software de teleconferencia instalado, en su momento se
              recomendará cual
            </li>
          </ul>
        </ServiceCard>
        <ServiceCard
          className="service-two"
          image={MeetingImage}
          image2x={`${MeetingImage}, ${MeetingImage2x} 2x`}
          title="Asambleas Presenciales"
        >
          <p className="article-description" data-testid="meeting">
            Con el fin de brindar agilidad y transparencia en el desarrollo de
            las asambleas, le ofrecemos a nuestros clientes un software el cual
            permite consolidar de forma rápida la sumatoria del coeficiente
            tanto en el registro de la asistencia como en alguna votación.
            <br />
            <br />
            A cada propietario se le hace entrega de un carné de registro el
            cual contiene su nombre, el número del inmueble, local o casa, el
            coeficiente correspondiente y un código de barras. Este último es
            leído para dejar registro de la asistencia y sumar el coeficiente en
            el software.
            <br />
            <br />
            Los carné de votación permiten diferenciar entre votos a favor, en
            contra o blanco. Estos se programan de acuerdo a la necesidad de
            cada asamblea.
            <br />
            <br />
            El software garantiza el registro único de cada copropietario y
            permite que este vote una sola vez en cada oportunidad. Es decir,
            ninguna persona podrá votar en repetidas ocaciones por el mismo tema
            en cuestión.
            <br />
            <br />
            Posterior a la asamblea, en un plazo de tres días hábiles se enviará
            a través de correo electrónico un resumen de la asamblea el cual
            contiene la siguiente información:
          </p>
          <ul className="article-list" data-testid="meeting">
            <li>Nombre del copropietario.</li>
            <li>Coeficiente.</li>
            <li>Hora de ingreso.</li>
            <li>Coeficiente acumulado.</li>
          </ul>
        </ServiceCard>
        <ServiceCard
          className="service-three"
          image={BillingImage}
          image2x={`${BillingImage}, ${BillingImage2x} 2x`}
          title="Facturación"
        >
          <p className="article-description" data-testid="billing">
            Nuestro servicio de facturación busca ofrecerle eficiencia y
            claridad en los cobros de las cuotas de administración.
            <br />
            <br />
            Después de recibir un excel con el detalle de los cobros del mes, se
            le entregará en un plazo de tres días una factura por inmueble la
            cual consta de dos cuerpos en un formato preimpreso: uno para el
            propietario y uno para la entidad financiera.
            <br />
            <br />
            Además de esto, se entregará el informe de relación de facturas el
            cual contiene:
          </p>
          <ul className="article-list" data-testid="billing">
            <li>El pago del mes anterior</li>
            <li>Número de cuotas vencidas</li>
            <li>Intereses vencidos</li>
            <li>Intereses del mes</li>
            <li>Cuota mensual de la administración del mes</li>
            <li>Cuotas extras o multas</li>
            <li>Detalle de cobros adicionales</li>
            <li>Saldo a favor en caso de aplicar</li>
            <li>Valor total a pagar de cada propietario</li>
          </ul>
          <p className="article-description" data-testid="billing">
            Esta información también será enviada a la contadora para que haga
            la respectiva conciliación de la cartera.
            <br />
            <br />
            Una vez entregada la facturación, el administrador podrá consultar
            desde el portal web informes como estados de cuenta de los
            copropietarios, relación de facturas o cartera por edades, entre
            otros.
          </p>
        </ServiceCard>
      </section>
      <section className="clients" id="clients" role="contentinfo">
        <h2 className="title clients-title">Nuestros Clientes</h2>
        <div className="clients-images-container">
          <figure className="client-image" data-testid="clientImage">
            <img
              src={CityPlazaLogo}
              title={CityPlazaLogo}
              alt={CityPlazaLogo}
            />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img src={DelEsteLogo} title={DelEsteLogo} alt={DelEsteLogo} />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img src={MayorcaLogo} title={MayorcaLogo} alt={MayorcaLogo} />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img
              src={NuestroUrabaLogo}
              title={NuestroUrabaLogo}
              alt={NuestroUrabaLogo}
            />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img
              src={NuevoConquistadoresBorealLogo}
              title={NuevoConquistadoresBorealLogo}
              alt={NuevoConquistadoresBorealLogo}
            />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img src={PISLogo} title={PISLogo} alt={PISLogo} />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img
              src={PlacesLogo}
              title={PlacesLogo}
              alt={PlacesLogo}
              width="150px"
            />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img
              src={PremiumPlazaLogo}
              title={PremiumPlazaLogo}
              alt={PremiumPlazaLogo}
            />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img
              src={PuntoClaveLogo}
              title={PuntoClaveLogo}
              alt={PuntoClaveLogo}
            />
          </figure>
          <figure className="client-image" data-testid="clientImage">
            <img
              src={SanNicolasLogo}
              title={SanNicolasLogo}
              alt={SanNicolasLogo}
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default Home;
