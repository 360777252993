import React from "react";
import { Link } from "react-router-dom";

import "../assets/styles/NotFound.css";

const NotFound = () => {
  return (
    <section className="not-found-container">
      <span className="not-found-404">404</span>
      <h1 className="not-found-message">
        Página no encontrada en nuestro servidor.
      </h1>
      <Link className="btn scaf-accept-btn not-found-btn" to="/">
        Ir a la página de inicio
      </Link>
    </section>
  );
};

export default NotFound;
