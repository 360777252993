import React, { useEffect, useId, useState } from "react";
import { connect } from "react-redux";
import {
  JOINTS_NOT_FOUND,
  JOINT_INPUT_DEFAULT_OPTION,
  JOINT_INPUT_LABEL,
  REPORT_GENERATION_FAILED,
  USERDATA,
} from "../../types";
import { getBillingJointRequest } from "../../actions";
import Loading from "../../components/Loading";

import "../../assets/styles/BalanceByAge.css";
import { getStorage } from "../../utils";
import { downloadReport, requests } from "../../axios";
import { useFormik } from "formik";

const BalanceByAge = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.billingJoints.length) {
        const response = await requests.users.getUsersBillingJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getBillingJointRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
    },
    onSubmit: async (values) => {
      setRequestState({
        ...requestState,
        loading: true,
      });
      try {
        const response = await requests.reports.getBalanceByAgeReport(
          values.jointId
        );
        downloadReport(response.data, "Cartera por Edades", "pdf");
        setRequestState({ ...requestState, loading: false });
      } catch (error) {
        console.log(error);
        setRequestState({ loading: false, error: REPORT_GENERATION_FAILED });
      }
    },
  });

  const options =
    props.billingJoints.length &&
    props.billingJoints.map((billing, index) => {
      return (
        <option value={billing.joint.jointId} key={`${id}-${index}`}>
          {billing.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <section className="userform" role="form">
          <form
            className="square-form balance-age-grid"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="balance-age-title">Cartera por Edades</h2>
            <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>
            <select
              className="joint-input userform-select"
              id="joint"
              name="joint"
              data-testid="jointInput"
              {...formik.getFieldProps("jointId")}
              required
            >
              <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
              {options}
            </select>
            {requestState.error && (
              <h6 className="bad-request error-message" role="alert">
                {requestState.error}
              </h6>
            )}
            <input
              type="submit"
              className="btn-block btn scaf-accept-btn"
              value="Aceptar"
            />
          </form>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    billingJoints: state.billingJoints,
  };
};

const mapDispatchToProps = {
  getBillingJointRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceByAge);
