import React, { useEffect, useId, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import { getAssemblyJointsRequest } from "../../actions";
import {
  CREATE_CONCEPT_FAILED,
  JOINTS_NOT_FOUND,
  JOINT_INPUT_DEFAULT_OPTION,
  JOINT_INPUT_LABEL,
  SWAL_NOTIFICATION,
  USERDATA,
} from "../../types";
import "../../assets/styles/GenerateQuestion.css";
import IncrementDecrementInput from "../../components/IncrementDecrementInput";
import { getStorage } from "../../utils";
import { requests } from "../../axios";
import { useFormik } from "formik";

const GenerateQuestionV2 = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [answersQuantity, setAnswersQuantity] = useState(3);

  let quantityFlag = 0;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.assemblyJoints.length) {
        const response = await requests.users.getUsersAssemblyJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getAssemblyJointsRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const handleHeight = (e) => {
    const $input = e.target;
    $input.style.height = "auto";
    $input.style.height = `${$input.scrollHeight}px`;
  };

  const handleIncrement = () => {
    setAnswersQuantity(answersQuantity + 1);
  };

  const handleDecrement = () => {
    setAnswersQuantity(answersQuantity - 1 < 1 ? 1 : answersQuantity - 1);
  };

  const handleOnChange = (e) => {
    setAnswersQuantity(
      e.target.value ? parseInt(e.target.value) : e.target.value
    );
  };

  const resetValues = () => {
    setAnswersQuantity(3);
    formik.setValues({
      ...formik.initialValues,
      jointId: formik.values.jointId,
    });
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      question: "",
      A01: "Si",
      A02: "No",
      A03: "Blanco",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let flag = 0;
      const answersData = Array(answersQuantity)
        .fill(0)
        .map(() => {
          flag++;
          return {
            answerId: flag,
            jointId: formik.values.jointId,
            answerDescription: formik.values[`A0${flag}`],
          };
        });
      const questionData = {
        jointId: formik.values.jointId,
        conceptDescription: formik.values.question,
        active: 1,
        answers: answersData,
      };
      try {
        const response = await requests.assemblyConcepts.saveConcept(
          questionData
        );
        response.data &&
          SWAL_NOTIFICATION.fire({
            icon: "success",
            title:
              "La pregunta ha sido creada con éxito y se encuentra activa.",
          });
        resetValues();
      } catch (error) {
        console.log(error);
        setRequestState({
          ...requestState,
          error: CREATE_CONCEPT_FAILED,
        });
      }
    },
  });

  const options =
    props.assemblyJoints.length &&
    props.assemblyJoints.map((assembly, index) => {
      return (
        <option value={assembly.joint.jointId} key={`${id}-${index}`}>
          {assembly.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <form
              className="square-form generate-question-grid"
              onSubmit={formik.handleSubmit}
            >
              <h2 className="generate-question-title">Generar Pregunta</h2>
              <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>
              <select
                className="joint-input userform-select"
                id="joint"
                name="joint"
                data-testid="jointInput"
                {...formik.getFieldProps("jointId")}
                required
              >
                <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
                {options}
              </select>
              <h5 className="question-label">Pregunta</h5>
              <textarea
                id="question"
                name="question"
                className="userform-textarea question-input"
                onKeyDown={handleHeight}
                data-testid="questionInput"
                {...formik.getFieldProps("question")}
                required
              />
              <h5 className="answers-quantity-label">Respuestas</h5>
              <IncrementDecrementInput
                className="answers-quantity-input"
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                handleOnChange={handleOnChange}
                quantity={answersQuantity}
              />
              <div id="answers" className="answers-container">
                {Array(answersQuantity)
                  .fill(0)
                  .map(() => {
                    quantityFlag++;
                    return (
                      <div key={quantityFlag} className="answer">
                        <h5 className="answer-label">Opción {quantityFlag}</h5>
                        <textarea
                          id={`A0${quantityFlag}`}
                          name={`A0${quantityFlag}`}
                          className="userform-textarea"
                          onKeyDown={handleHeight}
                          {...formik.getFieldProps(`A0${quantityFlag}`)}
                          data-testid="answerInput"
                          required
                        />
                      </div>
                    );
                  })}
              </div>
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
              <input
                type="submit"
                className="btn-block btn scaf-accept-btn"
                value="Generar"
              />
            </form>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyJoints: state.assemblyJoints,
    quantity: state.quantity,
  };
};

const mapDispatchToProps = {
  getAssemblyJointsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateQuestionV2);
