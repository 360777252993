import React from "react";

import "../assets/styles/Loading.css";

const Loading = (props) => {
  return (
    <>
      <div className="building">
        <div className="square s-1" data-testid="loading"></div>
        <div className="square s-2" data-testid="loading"></div>
        <div className="square s-3" data-testid="loading"></div>
        <div className="square s-4" data-testid="loading"></div>
        <div className="square s-5" data-testid="loading"></div>
        <div className="square s-6" data-testid="loading"></div>
        <div className="square s-7" data-testid="loading"></div>
        <div className="square s-8" data-testid="loading"></div>
        <div className="square s-9" data-testid="loading"></div>
      </div>
    </>
  );
};

export default Loading;
