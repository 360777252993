import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  GET_ASSEMBLY_APARTMENTS_REQUEST,
  GET_BILLING_JOINT_REQUEST,
  GET_BILLING_APARTMENTS_REQUEST,
  GET_ASSEMBLY_CONCEPTS_REQUEST,
  GET_ASSEMBLY_JOINT_REQUEST,
  GET_ZOOM_MEETING_REQUEST,
} from "../types";

export const logInRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
});

export const logOutRequest = (payload) => ({
  type: LOGOUT_REQUEST,
  payload,
});

export const getAssemblyJointsRequest = (payload) => ({
  type: GET_ASSEMBLY_JOINT_REQUEST,
  payload,
});

export const getAssemblyApartmentsRequest = (payload) => ({
  type: GET_ASSEMBLY_APARTMENTS_REQUEST,
  payload,
});

export const getBillingJointRequest = (payload) => ({
  type: GET_BILLING_JOINT_REQUEST,
  payload,
});

export const getBillingApartmentsRequest = (payload) => ({
  type: GET_BILLING_APARTMENTS_REQUEST,
  payload,
});

export const getAssemblyConceptsRequest = (payload) => ({
  type: GET_ASSEMBLY_CONCEPTS_REQUEST,
  payload,
});

export const getZoomMeetingRequest = (payload) => ({
  type: GET_ZOOM_MEETING_REQUEST,
  payload,
});