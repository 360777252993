import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  JOINTS_NOT_FOUND,
  REPORT_GENERATION_FAILED,
  USERDATA,
} from "../../types";
import {
  getAssemblyJointsRequest,
  getAssemblyConceptsRequest,
} from "../../actions";
import Loading from "../../components/Loading";
import "../../assets/styles/AssemblyReporter.css";
import { requests, downloadReport } from "../../axios";
import { getStorage } from "../../utils";
import { useFormik } from "formik";
import JointsConceptInput from "../../components/JointsConceptInput";

const AssemblyReporter = (props) => {
  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.assemblyJoints.length) {
        const response = await requests.users.getUsersAssemblyJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getAssemblyJointsRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      conceptId: "",
      format: "pdf",
    },
    onSubmit: async (values) => {
      setRequestState({ loading: true, error: null });
      const $conceptDescription = document.querySelector("#concept");
      const xlsxFormat = values.format === "xlsx";
      try {
        if (values.conceptId === "1") {
          const response = await requests.reports.getAssistanceReport(
            values.jointId,
            xlsxFormat
          );
          downloadReport(
            response.data,
            "Registro de Asistencia",
            values.format
          );
        } else {
          const response = await requests.reports.getVoteReport(
            {
              jointId: values.jointId,
              conceptId: values.conceptId,
            },
            xlsxFormat
          );
          downloadReport(
            response.data,
            `Votación ${values.conceptId - 1} ${
              $conceptDescription.options[values.conceptId].text
            }`,
            values.format
          );
        }
        setRequestState({ ...requestState, loading: false });
      } catch (error) {
        console.log(error.response.status);
        setRequestState({ loading: false, error: REPORT_GENERATION_FAILED });
      }
    },
  });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <form
              className="square-form assembly-reporter-grid"
              onSubmit={formik.handleSubmit}
            >
              <h2 className="assembly-reporter-title">Generar Reportes</h2>
              <JointsConceptInput
                showTitles={true}
                formik={formik}
                setRequestState={setRequestState}
              />
              <h5 className="format-options-labels">Formato</h5>
              <div className="options-block">
                <div className="option">
                  <input
                    type="radio"
                    id="pdfFormat"
                    name="format"
                    data-testid="pdfFormat"
                    value="pdf"
                    checked={formik.values.format === "pdf"}
                    onChange={() => {
                      formik.setFieldValue("format", "pdf");
                    }}
                  />
                  <label htmlFor="pdfFormat">PDF</label>
                </div>
                <div className="option">
                  <input
                    type="radio"
                    id="xlsxFormat"
                    name="format"
                    data-testid="xlsxFormat"
                    value="xlsx"
                    checked={formik.values.format === "xlsx"}
                    onChange={() => {
                      formik.setFieldValue("format", "xlsx");
                    }}
                  />
                  <label htmlFor="xlsxFormat">Excel</label>
                </div>
              </div>
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
              <input
                type="submit"
                className="btn-block btn scaf-accept-btn"
                value="Generar"
              />
            </form>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyJoints: state.assemblyJoints,
    assemblyConcepts: state.assemblyConcepts,
  };
};

const mapDispatchToProps = {
  getAssemblyJointsRequest,
  getAssemblyConceptsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyReporter);
