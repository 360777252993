import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../components/Layout";
import Home from "../pages/Home";
import LogIn from "../pages/LogIn";
import UserHome from "../pages/UserHome";
import Register from "../pages/assembly/Register";
import NotFound from "../pages/NotFound";

import "../assets/styles/App.css";
import "../assets/styles/Swal.css";
import BillsRelationship from "../pages/joint/BillsRelationship";
import BalanceByAge from "../pages/joint/BalanceByAge";
import StatementOfAccount from "../pages/joint/StatementOfAccount";
import MonthlyPayments from "../pages/joint/MonthlyPayments";
import Debtors from "../pages/joint/Debtors";
import StatementOfAccountOwner from "../pages/owner/StatementOfAccount";
import Bills from "../pages/owner/Bills";
import CashReceipts from "../pages/owner/CashReceipts";
import Quorum from "../pages/assembly/Quorum";
import Vote from "../pages/assembly/Vote";
import AssemblyPanel from "../pages/assembly/AssemblyPanel";
import GenerateQuestion from "../pages/assembly/GenerateQuestion";
import AssemblyReporter from "../pages/assembly/AssemblyReporter";
import LaunchPage from "../pages/assembly/LaunchPage";
import RegisterQR from "../pages/assembly/RegisterQR";
import ReverseRegister from "../pages/assembly/ReverseRegister";
import VoteQR from "../pages/assembly/VoteQR";
import ProofQR from "../pages/assembly/ProofQR";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/LogIn" element={<LogIn />} />
          <Route path="/UserHome" element={<UserHome />} />
          <Route path="/BillsRelationship" element={<BillsRelationship />} />
          <Route path="/BalanceByAge" element={<BalanceByAge />} />
          <Route path="/StatementOfAccount" element={<StatementOfAccount />} />
          <Route path="/MonthlyPayments" element={<MonthlyPayments />} />
          <Route path="/Debtors" element={<Debtors />} />
          <Route
            path="/StatementOfAccountOwner"
            element={<StatementOfAccountOwner />}
          />
          <Route path="/Bills" element={<Bills />} />
          <Route path="/CashReceipts" element={<CashReceipts />} />
          <Route path="/LaunchPage" element={<LaunchPage />}>
            <Route path=":zoomId/:zoomPassword" element={<LaunchPage />} />
          </Route>
          <Route
            path="/Register/:jointId/:user/:password/:jointName"
            element={<Register />}
          >
            <Route path=":zoomId/:zoomPassword" element={<Register />} />
          </Route>
          <Route path="/RegisterQR" element={<RegisterQR />} />
          <Route path="/Vote/:jointId" element={<Vote />} />
          <Route path="/VoteQR/:username" element={<VoteQR />} />
          <Route path="/PruebaQR" element={<ProofQR />} />
          <Route path="/Quorum" element={<Quorum />} />
          <Route path="/AssemblyPanel" element={<AssemblyPanel />} />
          <Route path="/ReverseRegister" element={<ReverseRegister />} />
          <Route path="/GenerateQuestion" element={<GenerateQuestion />} />
          <Route path="/AssemblyReporter" element={<AssemblyReporter />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
