import React from "react";
import "../assets/styles/IncrementDecrementInput.css";
import { RxPlus, RxMinus } from "react-icons/rx";

const IncrementDecrementInput = (props) => {
  return (
    <div className={`input-container ${props.className}`}>
      <RxMinus
        className="functional-btn"
        onClick={props.handleDecrement}
        data-testid="decrement"
      />
      <input
        type="number"
        id="quantityInput"
        className="userform-input-text quantity-input"
        data-testid="quantityInput"
        value={props.quantity}
        onChange={props.handleOnChange}
      />
      <RxPlus
        className="functional-btn"
        onClick={props.handleIncrement}
        data-testid="increment"
      />
    </div>
  );
};

export default IncrementDecrementInput;
