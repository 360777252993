import React from "react";
import { connect } from "react-redux";
import "../assets/styles/UserHome.css";

const UserHome = (props) => {
  return (
    <>
      <h1 className="welcome-message" data-testid="welcomeMessage">
        Bienvenido a <span className="scaf">SCAF</span>, {props.user.name}.
      </h1>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(UserHome);
