import React from "react";

import "../assets/styles/Footer.css";

const Footer = () => {
  return (
    <footer data-testid="footer">
      <p>
        sistemas.scaf@gmail.com | Celular: 3012783815
        <br />
        SCAF. Sistema de Control de Asambleas y Facturación
      </p>
    </footer>
  );
};

export default Footer;
