import React, { useState, useEffect, useId } from "react";
import { connect } from "react-redux";
import { requests } from "../../axios";
import {
  JOINTS_NOT_FOUND,
  USERDATA,
  REGISTER_FAILED,
  SWAL_NOTIFICATION,
  JOINT_INPUT_LABEL,
  JOINT_INPUT_DEFAULT_OPTION,
  APARTMENT_LABEL,
  APARTMENT_INPUT_DEFAULT_OPTION,
  SWAL_QUESTION,
  APARTMENTS_NOT_FOUND,
  GET_COEFFICIENT_FAILED,
} from "../../types";
import { getAssemblyJointsRequest } from "../../actions";
import Loading from "../../components/Loading";
import "../../assets/styles/assembly/RegisterQR.css";
import { getStorage } from "../../utils";
import { useFormik } from "formik";
import MultiSelect from "../../components/MultiSelect";
import AssemblyQuickAccessButtons from "../../components/AssemblyQuickAccessButtons";

const RegisterQR = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [apartmentState, setApartmentState] = useState({
    unregisteredApartments: {},
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.assemblyJoints.length) {
        const response = await requests.users.getUsersAssemblyJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getAssemblyJointsRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      apartments: [],
      qrCode: "",
    },
    onSubmit: async (values) => {
      try {
        resetValues();
        const joint = props.assemblyJoints.filter(
          (joint) => joint.jointId === parseInt(values.jointId)
        )[0].joint;
        await normalRegister(values);
        joint.isSectorial && (await sectorialRegister(joint.sectors, values));
        handleApartments();
        setRequestState({
          ...requestState,
          error: null,
        });
        SWAL_NOTIFICATION.fire({
          icon: "success",
          title: "Inmuebles registrados exitosamente.",
        });
      } catch (error) {
        resetValues();
        error.response.status === 409
          ? SWAL_NOTIFICATION.fire({
              icon: "error",
              title: "El inmueble ya se encuentra registrado.",
            })
          : setRequestState({
              ...requestState,
              error: REGISTER_FAILED,
            });
      }
    },
  });

  const resetValues = () => {
    formik.setValues({
      ...formik.values,
      apartments: [],
      qrCode: "",
    });
  };

  const handleApartments = async () => {
    const jointId = document.getElementById("joint").value;
    formik.setFieldValue("jointId", jointId);
    const response =
      jointId && (await requests.joints.getAssemblyApartments(jointId));
    setUnregisteredApartments(response.data);
  };

  const setUnregisteredApartments = (apartments) => {
    const unregisteredApartments = apartments
      .filter((apartment) => apartment.userId === null)
      .map((apartment) => {
        return {
          value: apartment.apartmentId,
          label: `${apartment.apartmentId} - ${apartment.owner}`,
        };
      });
    setApartmentState({
      unregisteredApartments: unregisteredApartments,
    });
  };

  const normalRegister = async (values) => {
    await Promise.all(
      values.apartments.map(async (apartment) => {
        await requests.assemblyApartments.assignUserId({
          apartmentId: apartment.value,
          jointId: values.jointId,
          userId: values.qrCode,
        });
        await requests.assembly.register({
          jointId: values.jointId,
          apartmentId: apartment.value,
          conceptId: 1,
          voteId: 1,
        });
      })
    );
  };

  const sectorialRegister = async (sectors, values) => {
    const apartmentsData = sectors.flatMap((sector) => {
      return values.apartments.map((apartment) => {
        return { sectorId: sector.sectorId, apartmentId: apartment.value };
      });
    });
    try {
      await Promise.all(
        apartmentsData.map(async (apartment) => {
          await requests.assemblyApartments.assignUserId({
            apartmentId: apartment.apartmentId,
            jointId: apartment.sectorId,
            userId: values.qrCode,
          });
          await requests.assembly.sectorialRegister({
            jointId: apartment.sectorId,
            apartmentId: apartment.apartmentId,
            conceptId: 1,
            voteId: 1,
          });
        })
      );
    } catch (error) {
      error.response.status !== 404 &&
        setRequestState({
          ...requestState,
          error: REGISTER_FAILED,
        });
    }
  };

  const options =
    props.assemblyJoints.length &&
    props.assemblyJoints.map((assembly, index) => {
      return (
        <option value={assembly.joint.jointId} key={`${id}-${index}`}>
          {assembly.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <form
              className="square-form register-qr-grid"
              onSubmit={formik.handleSubmit}
            >
              <h2 className="register-qr-title">Registro de Asistencia</h2>
              <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>
              <select
                className="joint-input userform-select"
                id="joint"
                name="joint"
                data-testid="jointInput"
                value={formik.values.jointId}
                onChange={handleApartments}
              >
                <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
                {options}
              </select>
              <h5 className="apartments-label">{APARTMENT_LABEL}</h5>
              <MultiSelect
                className="apartments-input"
                id="apartments"
                name="apartments"
                placeholder={APARTMENT_INPUT_DEFAULT_OPTION}
                handleOnChange={(value) => {
                  formik.setFieldValue("apartments", value);
                }}
                value={formik.values.apartments}
                options={
                  apartmentState.unregisteredApartments.length
                    ? apartmentState.unregisteredApartments
                    : []
                }
              />
              <h5 className="qr-code-label">Código QR</h5>
              <input
                className="qr-code-input userform-input-text"
                type="text"
                inputMode="numeric"
                id="qrCode"
                name="qrCode"
                data-testid="qrCodeInput"
                onChange={formik.handleChange}
                value={formik.values.qrCode}
                required
              />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
              <input
                type="submit"
                className="btn btn-block scaf-accept-btn"
                value="Registrar"
              />
            </form>
          </section>
          <AssemblyQuickAccessButtons />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyJoints: state.assemblyJoints,
  };
};

const mapDispatchToProps = {
  getAssemblyJointsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterQR);
