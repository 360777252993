import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import {
  getBillingJointRequest,
  getBillingApartmentsRequest,
} from "../../actions";
import { APARTMENTS_NOT_FOUND, USERDATA } from "../../types";
import "../../assets/styles/StatementOfAccountOwner.css";
import { getStorage } from "../../utils";
import { requests } from "../../axios";
import { useFormik } from "formik";
import JointsBillingApartmentInput from "../../components/JointsBillingApartmentInput";

const StatementOfAccount = (props) => {
  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const response = await requests.users.getUsersBillingApartments(
        props.user.userId || getStorage(USERDATA).userId
      );
      props.getBillingApartmentsRequest(response.data);
      await getJoints(response.data);
      setRequestState({
        ...requestState,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: APARTMENTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      apartmentId: "",
    },
  });

  const getJoints = async (billingApartments) => {
    const jointsData = await Promise.all(
      billingApartments.map(async (apartment) => {
        return requests.joints.getJointById(apartment.jointId);
      })
    );
    const joints = jointsData.map((joint) => {
      return [JSON.stringify(joint.data), joint.data];
    });
    const distinctJoints = [...new Map(joints).values()];
    props.getBillingJointRequest(distinctJoints);
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <form
              className="square-form statement-account-owner-grid"
              onSubmit={null}
            >
              <h2 className="statement-account-title">Estado de Cuenta</h2>
              <JointsBillingApartmentInput formik={formik} />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
            </form>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    billingJoints: state.billingJoints,
    billingApartments: state.billingApartments,
  };
};

const mapDispatchToProps = {
  getBillingJointRequest,
  getBillingApartmentsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementOfAccount);
