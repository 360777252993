import React, { useId } from "react";
import "../assets/styles/Table.css";

const ScafTable = ({ headers, rows }) => {
  const id = useId();
  const subId = useId();

  return (
    <table>
      <thead className="table-header" data-testid="tableHeader">
        <tr data-testid="rowHeader">
          {headers.map((header, index) => {
            return (
              <th key={`${id}-${index}`} data-testid="columnHeader">
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody data-testid="tableBody">
        {rows.length ? (
          rows.map((row, index) => {
            return (
              <tr key={`${subId}-${index}`} data-testid="rowBody">
                {headers.map((header) => {
                  return (
                    <td
                      key={`${id}-${subId}-${row[header]}`}
                      data-testid="columnBody"
                    >
                      {row[header]}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr data-testid="rowBody"></tr>
        )}
      </tbody>
    </table>
  );
};

export default ScafTable;
