import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  GET_ASSEMBLY_APARTMENTS_REQUEST,
  GET_BILLING_JOINT_REQUEST,
  GET_BILLING_APARTMENTS_REQUEST,
  GET_ASSEMBLY_CONCEPTS_REQUEST,
  GET_ASSEMBLY_JOINT_REQUEST,
  GET_ZOOM_MEETING_REQUEST,
} from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        typeNavBar: action.payload.profileId,
        user: action.payload,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        typeNavBar: 0,
        user: {},
        billingJoints: {},
        assemblyJoints: {},
        billingApartments: {},
        assemblyApartments: {},
        assemblyConcepts: {},
        zoomMeeting: {},
      };
    case GET_ASSEMBLY_APARTMENTS_REQUEST:
      return {
        ...state,
        assemblyApartments: action.payload,
      };
    case GET_BILLING_JOINT_REQUEST:
      return {
        ...state,
        billingJoints: action.payload,
      };
    case GET_ASSEMBLY_JOINT_REQUEST:
      return {
        ...state,
        assemblyJoints: action.payload,
      };
    case GET_BILLING_APARTMENTS_REQUEST:
      return {
        ...state,
        billingApartments: action.payload,
      };
    case GET_ASSEMBLY_CONCEPTS_REQUEST:
      return {
        ...state,
        assemblyConcepts: action.payload,
      };
    case GET_ZOOM_MEETING_REQUEST:
      return {
        ...state,
        zoomMeeting: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
