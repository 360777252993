import { useFormik } from "formik";
import React, { useEffect, useId, useState } from "react";
import { setStorage } from "../../utils";
import "../../assets/styles/VoteForm.css";
import { SWAL_NOTIFICATION, SWAL_QUESTION, USERDATA } from "../../types";

const ProofQR = (props) => {
  const id = useId();

  const [proofState, setProofState] = useState({
    answers: [
      { answerId: 1, answerDescription: "Si" },
      { answerId: 2, answerDescription: "No" },
      { answerId: 3, answerDescription: "Blanco" },
    ],
    isVoted: false,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setStorage(USERDATA, JSON.stringify({ profileId: 6 }));
  };

  const formik = useFormik({
    initialValues: {
      answer: "",
    },
    onSubmit: async (values) => {
      const answerSelected = proofState.answers.filter(
        (answer) => answer.answerId === values.answer
      )[0].answerDescription;
      const swalAnswer = await SWAL_QUESTION.fire({
        icon: "question",
        html: `<p>Seleccionó la opción <b>"${answerSelected}"</b>.<br/> ¿Está seguro de su respuesta?</p>`,
        showDenyButton: true,
        confirmButtonText: "Sí",
        denyButtonText: "No",
      });
      if (swalAnswer.isConfirmed) {
        setProofState({ ...proofState, isVoted: true });
        SWAL_NOTIFICATION.fire({
          icon: "success",
          title: "Votación exitosa.",
        });
      }
    },
  });

  return (
    <>
      {proofState.isVoted ? (
        <h1 className="completion-message" data-testid="completionMessage">
          Gracias por su votación.
        </h1>
      ) : (
        <section className="userform" role="form">
          <form
            className="square-form vote-grid"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="vote-title">Votación</h2>
            <h4 className="concept-description">
              ¿Es claro para usted el proceso de votación?
            </h4>
            <div className="options-block">
              {proofState.answers.map((answer, index) => {
                return (
                  <div className="option" key={`${id}-${index}`}>
                    <input
                      type="radio"
                      id={answer.answerId}
                      name="option"
                      value={answer.answerId}
                      onChange={() => {
                        formik.setFieldValue("answer", answer.answerId);
                      }}
                    />
                    <label htmlFor={answer.answerId}>
                      {answer.answerDescription}
                    </label>
                  </div>
                );
              })}
            </div>
            <input
              type="submit"
              className="btn-block btn scaf-accept-btn"
              value="Votar"
            />
          </form>
        </section>
      )}
    </>
  );
};

export default ProofQR;
