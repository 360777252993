import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { requests } from "../../axios";
import {
  JOINTS_NOT_FOUND,
  CONCEPT_UPDATE_FAILED,
  CONCEPT_RESET_FAILED,
  DELETE_CONCEPT_FAILED,
  USERDATA,
  SWAL_NOTIFICATION,
  SWAL_QUESTION,
} from "../../types";
import {
  getAssemblyJointsRequest,
  getAssemblyConceptsRequest,
} from "../../actions";
import Loading from "../../components/Loading";
import "../../assets/styles/AssemblyPanel.css";
import { getStorage } from "../../utils";
import JointsConceptInput from "../../components/JointsConceptInput";
import { useFormik } from "formik";

const AssemblyPanel = (props) => {
  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.assemblyJoints.length) {
        const response = await requests.users.getUsersAssemblyJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getAssemblyJointsRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const getConceptData = () => {
    return props.assemblyConcepts.filter(
      (concept) => concept.conceptId === parseInt(formik.values.conceptId)
    );
  };

  const setConceptsAfterDeleteOne = async (jointId, conceptId) => {
    formik.setFieldValue("conceptId", "");
    const concepts = props.assemblyConcepts.filter(
      (concept) => concept.conceptId !== conceptId
    );
    props.getAssemblyConceptsRequest(concepts);
  };

  const handleActive = async () => {
    try {
      setRequestState({ ...requestState, error: null });
      const conceptData = getConceptData();
      const newConceptStatus = conceptData[0].active === 0 ? 1 : 0;
      const conceptUpdatedResponse =
        await requests.assemblyConcepts.updateConceptStatus({
          conceptId: conceptData[0].conceptId,
          jointId: conceptData[0].jointId,
          active: newConceptStatus,
        });
      const message =
        conceptUpdatedResponse.data.active === 0
          ? `El concepto ${conceptUpdatedResponse.data.conceptDescription} se desactivó correctamente.`
          : `El concepto ${conceptUpdatedResponse.data.conceptDescription} se activó correctamente.`;
      SWAL_NOTIFICATION.fire({
        icon: "success",
        title: message,
      });
      const conceptsResponse =
        await requests.joints.getAssemblyConceptsByJointId(
          conceptUpdatedResponse.data.jointId
        );
      props.getAssemblyConceptsRequest(conceptsResponse.data);
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        ...requestState,
        error: CONCEPT_UPDATE_FAILED,
      });
    }
  };

  const handleReset = async () => {
    try {
      setRequestState({ ...requestState, error: null });
      const conceptData = getConceptData();
      const swalAnswer = await SWAL_QUESTION.fire({
        icon: "warning",
        html: `<p>¿Está seguro en reiniciar el conteo de ${conceptData[0].conceptDescription}?</p>`,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      });
      if (swalAnswer.isConfirmed) {
        await requests.assembly.deleteAllApartmentsRegistered({
          conceptId: conceptData[0].conceptId,
          jointId: conceptData[0].jointId,
        });
        SWAL_NOTIFICATION.fire({
          icon: "success",
          title: `El conteo de ${conceptData[0].conceptDescription} se reinició correctamente.`,
        });
      }
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        ...requestState,
        error: CONCEPT_RESET_FAILED,
      });
    }
  };

  const handleDelete = async () => {
    try {
      setRequestState({ ...requestState, error: null });
      const conceptData = getConceptData();
      const swalAnswer = await SWAL_QUESTION.fire({
        icon: "warning",
        html: `<p>Está a punto de eliminar el concepto ${conceptData[0].conceptDescription}. ¿Está seguro?</p>`,
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: "No",
      });
      if (swalAnswer.isConfirmed) {
        await requests.assemblyConcepts.deleteConcept({
          conceptId: conceptData[0].conceptId,
          jointId: conceptData[0].jointId,
        });
        await setConceptsAfterDeleteOne(
          conceptData[0].jointId,
          conceptData[0].conceptId
        );
        SWAL_NOTIFICATION.fire({
          icon: "success",
          title: `El concepto ${conceptData[0].conceptDescription} se eliminó correctamente.`,
        });
      }
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        ...requestState,
        error: DELETE_CONCEPT_FAILED,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      conceptId: "",
    },
  });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <div className="square-form assembly-panel-grid">
              <h2 className="assembly-panel-title">Panel Asambleas</h2>
              <JointsConceptInput
                showTitles={true}
                formik={formik}
                setRequestState={setRequestState}
              />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
              <div className="assembly-panel-buttons">
                <input
                  type="submit"
                  id="active"
                  className="btn scaf-accept-btn"
                  value="Activar/Desactivar"
                  onClick={handleActive}
                />
                <input
                  type="submit"
                  className="btn scaf-accept-btn"
                  value="Reiniciar"
                  onClick={handleReset}
                />
                <input
                  type="submit"
                  className="btn scaf-reject-btn"
                  value="Eliminar"
                  onClick={handleDelete}
                />
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyJoints: state.assemblyJoints,
    assemblyConcepts: state.assemblyConcepts,
  };
};

const mapDispatchToProps = {
  getAssemblyJointsRequest,
  getAssemblyConceptsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyPanel);
