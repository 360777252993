import React from "react";
import Select, { components } from "react-select";

const styles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "15px",
    border: "1px solid #cacaca",
    boxShadow: "0 0 0 #cacaca",
    maxWidth: "100%",
    transition: "box-shadow 0.5s, border-color 0.25s ease-in-out",
    ":hover": {
      outline: "none",
      borderColor: "#cacaca",
      boxShadow: "0 0 5px #cacaca",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    border: "none",
    borderRadius: "15px",
    ":hover": {
      outline: "none",
    },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#dfdfdf",
    maxWidth: "8em",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: (isSelected && "#cf2929") || (isFocused && "#f6c1c1"),
      color: (isSelected && "#ffffff") || (isFocused && "#000000"),
    };
  },
};

const options = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiSelect = (props) => {
  return (
    <Select
      className={props.className}
      inputId={props.id}
      name={props.name}
      options={props.options}
      isMulti
      required
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      components={{ options }}
      placeholder={props.placeholder}
      onChange={props.handleOnChange}
      value={props.value}
      styles={styles}
    />
  );
};

export default MultiSelect;
