import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CONCEPT_DISABLE } from "../../types";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import { requests } from "../../axios";
import VoteForm from "../../components/VoteForm";

const Vote = (props) => {
  const params = useParams();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [voteState, setVoteState] = useState({
    data: null,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const response = await requests.joints.getActiveConcept(params.jointId);
      setVoteState({ data: response.data });
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: CONCEPT_DISABLE,
      });
    }
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <VoteForm
          voteState={voteState}
          setVoteState={setVoteState}
          requestState={requestState}
          setRequestState={setRequestState}
          redirect={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    assemblyApartments: state.assemblyApartments,
  };
};

export default connect(mapStateToProps, null)(Vote);
