import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import {
  getBillingJointRequest,
  getBillingApartmentsRequest,
} from "../../actions";
import { APARTMENTS_NOT_FOUND, BILLS_NOT_FOUND, USERDATA } from "../../types";
import "../../assets/styles/Bills.css";
import { requests } from "../../axios";
import { getStorage } from "../../utils";
import { useFormik } from "formik";
import JointsBillingApartmentInput from "../../components/JointsBillingApartmentInput";
import ScafTable from "../../components/ScafTable";

const Bills = (props) => {
  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [billState, setBillState] = useState({
    bills: {},
  });

  const headers = ["Mes", "Cuenta de Cobro", "Total a Pagar", ""];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const response = await requests.users.getUsersBillingApartments(
        props.user.userId || getStorage(USERDATA).userId
      );
      props.getBillingApartmentsRequest(response.data);
      await getJoints(response.data);
      response.data.length === 1 &&
        (await getBillsInformation(
          response.data[0].jointId,
          response.data[0].apartmentId
        ));
      setRequestState({
        ...requestState,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: APARTMENTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      apartmentId: "",
    },
  });

  const getJoints = async (billingApartments) => {
    const jointsData = await Promise.all(
      billingApartments.map(async (apartment) => {
        return requests.joints.getJointById(apartment.jointId);
      })
    );
    const joints = jointsData.map((joint) => {
      return [JSON.stringify(joint.data), joint.data];
    });
    const distinctJoints = [...new Map(joints).values()];
    props.getBillingJointRequest(distinctJoints);
  };

  const handleTable = async () => {
    try {
      setRequestState({
        ...requestState,
        error: null,
      });
      const apartmentId = document.getElementById("apartment").value;
      formik.setFieldValue("apartmentId", apartmentId);
      await getBillsInformation(formik.values.jointId, apartmentId);
    } catch (error) {
      console.log(error);
      setRequestState({
        ...requestState,
        error: BILLS_NOT_FOUND,
      });
    }
  };

  const getBillsInformation = async (jointId, apartmentId) => {
    const response = await requests.movements.getApartmentsBills(
      jointId,
      apartmentId
    );
    const bills = response.data.map((bill) => {
      const period = bill.date;
      const totalToPay = bill.movementsDetail.reduce(
        (sum, detail) => sum + detail.periodFee + detail.overdueFee,
        0
      );
      return {
        Mes: period,
        "Cuenta de Cobro": bill.bill,
        "Total a Pagar": new Intl.NumberFormat("de-DE").format(totalToPay),
        "": (
          <input
            type="button"
            className="btn-block btn scaf-accept-btn"
            data-bill={bill.bill}
            onClick={handleExport}
            value="Exportar"
          />
        ),
      };
    });
    setBillState({ bills: bills });
  };

  const handleExport = (e) => {
    console.log(e.target.getAttribute("data-bill"));
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <div className="square-form bills-owner-grid">
              <h2 className="bills-owner-title">Cuentas de Cobro</h2>
              <JointsBillingApartmentInput
                formik={formik}
                handleOnChange={handleTable}
              />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
            </div>
          </section>
          <ScafTable headers={headers} rows={billState.bills} />
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    billingJoints: state.billingJoints,
    billingApartments: state.billingApartments,
  };
};

const mapDispatchToProps = {
  getBillingJointRequest,
  getBillingApartmentsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Bills);
