import React, { useState, useEffect, useId } from "react";
import { connect } from "react-redux";

import Loading from "../../components/Loading";
import { getBillingJointRequest } from "../../actions";
import "../../assets/styles/BillsRelationship.css";
import {
  MONTHS,
  JOINTS_NOT_FOUND,
  JOINTS_INFO_NOT_FOUND,
  USERDATA,
  JOINT_INPUT_LABEL,
  JOINT_INPUT_DEFAULT_OPTION,
  REPORT_GENERATION_FAILED,
} from "../../types";
import { getStorage } from "../../utils";
import { downloadReport, requests } from "../../axios";
import { useFormik } from "formik";

const BillsRelationship = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [yearState, setYearState] = useState({
    years: {},
  });

  const [monthState, setMonthState] = useState({
    months: {},
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.billingJoints.length) {
        const response = await requests.users.getUsersBillingJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getBillingJointRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const handleYear = async () => {
    setRequestState({ loading: true, error: null });
    setMonthState({ months: {} });
    const jointId = document.getElementById("joint").value;
    formik.setFieldValue("jointId", jointId);
    formik.setFieldValue("year", "");
    formik.setFieldValue("month", "");
    try {
      const response = await requests.movements.getYearsByJointId(jointId);
      setYearState({ years: response.data });
      setRequestState({ loading: false, error: null });
    } catch (error) {
      console.log(error);
      setRequestState({
        ...requestState,
        error: JOINTS_INFO_NOT_FOUND,
      });
    }
  };

  const handleMonth = async () => {
    setRequestState({ loading: true, error: null });
    const year = document.getElementById("year").value;
    formik.setFieldValue("year", year);
    formik.setFieldValue("month", "");
    try {
      const response = await requests.movements.getMonthsByJointIdAndYear(
        formik.values.jointId,
        year
      );
      setMonthState({ months: response.data });
      setRequestState({ loading: false, error: null });
    } catch (error) {
      console.log(error);
      setRequestState({
        ...requestState,
        error: JOINTS_INFO_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      year: "",
      month: "",
    },
    onSubmit: async (values) => {
      setRequestState({
        ...requestState,
        loading: true,
      });
      try {
        const response = await requests.reports.getBillsRelationshipReport({
          jointId: values.jointId,
          year: values.year,
          month: values.month,
        });
        downloadReport(
          response.data,
          `Relación de  Facturas ${values.month} - ${values.year}`,
          "pdf"
        );
        setRequestState({ ...requestState, loading: false });
      } catch (error) {
        console.log(error);
        setRequestState({ loading: false, error: REPORT_GENERATION_FAILED });
      }
    },
  });

  const options =
    props.billingJoints.length &&
    props.billingJoints.map((billing, index) => {
      return (
        <option value={billing.joint.jointId} key={`${id}-${index}`}>
          {billing.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <section className="userform" role="form">
          <form
            className="square-form bills-grid"
            onSubmit={formik.handleSubmit}
          >
            <h2 className="bills-title">Relación de Facturas</h2>
            <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>
            <select
              className="joint-input userform-select"
              id="joint"
              name="joint"
              data-testid="jointInput"
              value={formik.values.jointId}
              onChange={handleYear}
              required
            >
              <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
              {options}
            </select>
            <h5 className="date-label">Año / Mes</h5>
            <div className="date-input-container">
              <select
                className="userform-select date-input"
                id="year"
                name="year"
                data-testid="yearInput"
                value={formik.values.year}
                onChange={handleMonth}
                required
              >
                <option value="">Año</option>
                {yearState.years.length &&
                  yearState.years.map((year, index) => {
                    return (
                      <option value={year} key={`${id}-${index}`}>
                        {year}
                      </option>
                    );
                  })}
              </select>
              <select
                className="userform-select date-input"
                id="month"
                name="month"
                data-testid="monthInput"
                {...formik.getFieldProps("month")}
                required
              >
                <option value="">Mes</option>
                {monthState.months.length &&
                  monthState.months.map((month, index) => {
                    return (
                      <option value={month} key={`${id}-${index}`}>
                        {MONTHS[month]}
                      </option>
                    );
                  })}
              </select>
            </div>
            {requestState.error && (
              <h6 className="bad-request error-message" role="alert">
                {requestState.error}
              </h6>
            )}
            <input
              type="submit"
              className="btn-block btn scaf-accept-btn"
              value="Aceptar"
            ></input>
          </form>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    billingJoints: state.billingJoints,
  };
};

const mapDispatchToProps = {
  getBillingJointRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsRelationship);
