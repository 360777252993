import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import reportWebVitals from "./reportWebVitals";

import App from "./routes/App";
import reducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = {
  typeNavBar: 0,
  user: {},
  billingJoints: {},
  assemblyJoints: {},
  billingApartments: {},
  assemblyApartments: {},
  assemblyConcepts: {},
  zoomMeeting: {},
};
const store = createStore(reducer, initialState, composeEnhancers());

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
