import React from "react";
import { Link } from "react-router-dom";

const MenuItem = (props) => {
  return (
    <li className="nav-item">
      <Link className="nav-link" to={props.reference}>
        {props.text}
      </Link>
    </li>
  );
};

export default MenuItem;
