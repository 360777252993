//General
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const SWAL_QUESTION = MySwal.mixin({
  buttonsStyling: false,
  customClass: {
    container: "scaf-swal-container",
    title: "title",
    input: "scaf-swal-input",
    validationMessage: "error-message scaf-swal-validation-message",
    confirmButton: "btn scaf-accept-btn scaf-swal-btn",
    denyButton: "btn scaf-reject-btn scaf-swal-btn",
  },
});

export const SWAL_NOTIFICATION = SWAL_QUESTION.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 4000,
});

export const MONTHS = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

export const API_URL = process.env.REACT_APP_API_URL;

// Constants

export const TOKEN = "TOKEN";

export const USERDATA = "USERDATA";

export const APARTMENT_LABEL = "Inmueble";

export const JOINT_INPUT_LABEL = "Copropiedad Horizontal";

export const JOINT_INPUT_DEFAULT_OPTION = "Seleccione una copropiedad";

export const APARTMENT_INPUT_DEFAULT_OPTION = "Seleccione un inmueble";

export const CONCEPT_INPUT_LABEL = "Concepto";

export const CONCEPT_INPUT_DEFAULT_OPTION = "Seleccione un concepto";

//Error Messages

export const LOGIN_FAILED = "Usuario o Contraseña Inválido.";

export const JOINTS_NOT_FOUND =
  "No tiene conjuntos residenciales asociados. Comunicarse con el administrador de la plataforma.";

export const JOINTS_INFO_NOT_FOUND =
  "No hay información para el conjunto residencial seleccionado.";

export const JOINTS_APARTMENTS_NOT_FOUND =
  "No hay inmuebles asociados al conjunto residencial seleccionado. Comunicarse con el administrador de la plataforma.";

export const APARTMENTS_NOT_FOUND =
  "No tiene inmuebles asignados. Comunicarse con el administrador de la plataforma.";

export const BILLS_NOT_FOUND = "El inmueble no tiene cuentas de cobro.";

export const CASH_RECEIPTS_NOT_FOUND = "El inmueble no tiene recibos de caja.";

export const REPORT_GENERATION_FAILED =
  "No se puede generar el informe en estos momentos. Intente nuevamente.";

export const CASH_RECEIPT_GENERATION_FAILED =
  "No se puede generar el recibo de caja en estos momentos. Intente nuevamente.";

export const CREATE_CONCEPT_FAILED =
  "No se puede crear la pregunta en este momento. Intente nuevamente.";

export const CONCEPTS_NOT_FOUND = "El conjunto no tiene conceptos asociados.";

export const CONCEPT_UPDATE_FAILED =
  "No se puede actualizar el estado del concepto en este momento. Intente nuevamente.";

export const CONCEPT_RESET_FAILED =
  "No se puede reiniciar el conteo en este momento. Intente nuevamente.";

export const DELETE_CONCEPT_FAILED =
  "No se puede eliminar el concepto en este momento. Intente nuevamente.";

export const CONCEPT_DISABLE =
  "La votación no se encuentra activa. Intente nuevamente.";

export const REGISTER_FAILED =
  "No se puede registrar el inmueble en este momento. Intente nuevamente.";

export const GET_COEFFICIENT_FAILED =
  "No se puede consultar el coeficiente en este momento. Intente nuevamente.";

export const REVERSE_REGISTER_FAILED =
  "No se puede realizar el reverso en este momento. Intente nuevamente.";

export const REMOVE_QR_FAILED =
  "No se puede retirar el QR en este momento. Intente nuevamente.";

export const VOTE_FAILED =
  "No se puede votar en este momento. Intente nuevamente.";

export const VOTES_REGISTERED_FAILED =
  "No se encuentra disponible la información. Intente nuevamente.";

//Actions

export const LOGIN_REQUEST = "LOGIN_REQUEST";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const GET_ASSEMBLY_APARTMENTS_REQUEST =
  "GET_ASSEMBLY_APARTMENTS_REQUEST";

export const GET_BILLING_JOINT_REQUEST = "GET_BILLING_JOINT_REQUEST";

export const GET_ASSEMBLY_JOINT_REQUEST = "GET_ASSEMBLY_JOINT_REQUEST";

export const GET_BILLING_APARTMENTS_REQUEST = "GET_BILLING_APARTMENTS_REQUEST";

export const GET_ASSEMBLY_CONCEPTS_REQUEST = "GET_ASSEMBLY_CONCEPTS_REQUEST";

export const GET_ZOOM_MEETING_REQUEST = "GET_ZOOM_MEETING_REQUEST";
