import React from "react";
import { TbQrcodeOff, TbHomeQuestion } from "react-icons/tb";
import "../assets/styles/AssemblyQuickAccessButtons.css";
import {
  APARTMENTS_NOT_FOUND,
  GET_COEFFICIENT_FAILED,
  REMOVE_QR_FAILED,
  SWAL_NOTIFICATION,
  SWAL_QUESTION,
} from "../types";
import { requests } from "../axios";
import { connect } from "react-redux";

const AssemblyQuickAccessButtons = (props) => {
  const getCoefficient = async () => {
    const { value: information } = await SWAL_QUESTION.fire({
      title: "Consultar Coeficiente",
      inputPlaceholder: "Código QR",
      input: "text",
      inputValidator: (value) => {
        if (!value) {
          return "Por favor digite un código QR";
        }
      },
      inputAttributes: {
        inputMode: "numeric",
      },
      showLoaderOnConfirm: true,
      showDenyButton: true,
      confirmButtonText: "Consultar",
      denyButtonText: "Cancelar",
      preConfirm: async (qrCode) => {
        try {
          const response = await requests.users.getUsersAssemblyApartments(
            qrCode
          );

          const information = {
            apartments: [],
            coefficient: [],
          };

          const jointsId = getDistinctJoints(response.data);
          information.coefficient = jointsId.map((jointId) => {
            const jointName = props.assemblyJoints.filter(
              (joint) => joint.jointId === jointId
            )[0].joint.jointName;
            const coefficient = response.data
              .filter((apartment) => apartment.jointId === jointId)
              .reduce((sum, apartment) => sum + apartment.coefficient, 0)
              .toFixed(4);
            return `${jointName.toLowerCase()}: ${coefficient}`;
          });

          information.apartments = getDistinctApartments(response.data);

          return information;
        } catch (error) {
          error.response.status && error.response.status === 404
            ? SWAL_QUESTION.showValidationMessage(APARTMENTS_NOT_FOUND)
            : SWAL_QUESTION.showValidationMessage(GET_COEFFICIENT_FAILED);
        }
      },
    });
    information.apartments &&
      SWAL_QUESTION.fire({
        icon: "success",
        title: `Coeficiente acumulado`,
        html: `<div>
                <p>Inmuebles: ${information.apartments.join(", ")}</p>
                <p style="text-transform: capitalize;">${information.coefficient.join(
                  "<br>"
                )}</p>
              </div>`,
        confirmButtonText: "OK",
        // timer: 5000,
      });
  };

  const removeQR = async () => {
    const { value: qrCode } = await SWAL_QUESTION.fire({
      title: "Retirar QR",
      inputPlaceholder: "Código QR",
      input: "text",
      inputValidator: (value) => {
        if (!value) {
          return "Por favor digite un código QR";
        }
      },
      inputAttributes: {
        inputMode: "numeric",
      },
      showLoaderOnConfirm: true,
      showDenyButton: true,
      confirmButtonText: "Retirar",
      denyButtonText: "Cancelar",
      preConfirm: async (qrCode) => {
        try {
          const response = await requests.users.getUsersAssemblyApartments(
            qrCode
          );
          Promise.all(
            response.data.map(async (apartment) => {
              await requests.assemblyApartments.assignUserId({
                apartmentId: apartment.apartmentId,
                jointId: apartment.jointId,
                userId: null,
              });
            })
          );
        } catch (error) {
          error.response.status && error.response.status === 404
            ? SWAL_QUESTION.showValidationMessage(APARTMENTS_NOT_FOUND)
            : SWAL_QUESTION.showValidationMessage(REMOVE_QR_FAILED);
        }
      },
    });
    qrCode &&
      SWAL_NOTIFICATION.fire({
        icon: "success",
        title: "Retiro exitoso.",
      });
  };

  const getDistinctJoints = (data) => {
    const joints = data.map((apartment) => {
      return [apartment.jointId, apartment.jointId];
    });
    return [...new Map(joints).values()];
  };

  const getDistinctApartments = (data) => {
    let array = {};
    const apartments = data.map((apartment) => apartment.apartmentId);
    return apartments.filter((apartment) =>
      array.hasOwnProperty(apartment) ? false : (array[apartment] = true)
    );
  };

  return (
    <div className="quick-access-buttons">
      <button className="quick-btn" onClick={getCoefficient}>
        <TbHomeQuestion className="quick-btn-logo" />
        <p className="quick-lbl">Consultar Coeficiente</p>
      </button>
      <button className="quick-btn" onClick={removeQR}>
        <TbQrcodeOff className="quick-btn-logo" />
        <p className="quick-lbl">Retirar QR</p>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    assemblyJoints: state.assemblyJoints,
  };
};

export default connect(mapStateToProps, null)(AssemblyQuickAccessButtons);
