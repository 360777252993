import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import {
  getBillingJointRequest,
  getBillingApartmentsRequest,
} from "../../actions";
import { downloadReport, requests } from "../../axios";
import { getStorage } from "../../utils";
import {
  APARTMENTS_NOT_FOUND,
  CASH_RECEIPTS_NOT_FOUND,
  CASH_RECEIPT_GENERATION_FAILED,
  USERDATA,
} from "../../types";
import "../../assets/styles/CashReceipts.css";
import { useFormik } from "formik";
import JointsBillingApartmentInput from "../../components/JointsBillingApartmentInput";
import ScafTable from "../../components/ScafTable";

const CashReceipts = (props) => {
  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const headers = ["Mes", "Recibo de Caja", "Valor", ""];

  const [cashReceiptState, setCashReceiptState] = useState({
    cashReceipts: {},
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const response = await requests.users.getUsersBillingApartments(
        props.user.userId || getStorage(USERDATA).userId
      );
      props.getBillingApartmentsRequest(response.data);
      await getJoints(response.data);
      response.data.length === 1 &&
        (await getCashReceiptsInformation(
          response.data[0].jointId,
          response.data[0].apartmentId
        ));
      setRequestState({
        ...requestState,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      setRequestState({
        loading: false,
        error: APARTMENTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      apartmentId: "",
    },
  });

  const getJoints = async (billingApartments) => {
    const jointsData = await Promise.all(
      billingApartments.map(async (apartment) => {
        return requests.joints.getJointById(apartment.jointId);
      })
    );
    const joints = jointsData.map((joint) => {
      return [JSON.stringify(joint.data), joint.data];
    });
    const distinctJoints = [...new Map(joints).values()];
    props.getBillingJointRequest(distinctJoints);
  };

  const handleTable = async () => {
    try {
      setRequestState({
        ...requestState,
        error: null,
      });
      const apartmentId = document.getElementById("apartment").value;
      formik.setFieldValue("apartmentId", apartmentId);
      await getCashReceiptsInformation(formik.values.jointId, apartmentId);
    } catch (error) {
      console.log(error);
      setRequestState({
        ...requestState,
        error: CASH_RECEIPTS_NOT_FOUND,
      });
    }
  };

  const getCashReceiptsInformation = async (jointId, apartmentId) => {
    const response = await requests.cashReceipts.getApartmentsCashReceipts(
      jointId,
      apartmentId
    );
    const cashReceipts = response.data.map((cashReceipt) => {
      const period = cashReceipt.date;
      return {
        Mes: period,
        "Recibo de Caja": cashReceipt.cashReceipt,
        Valor: new Intl.NumberFormat("de-DE").format(cashReceipt.amount),
        "": (
          <input
            type="button"
            className="btn-block btn scaf-accept-btn"
            data-joint-id={cashReceipt.jointId}
            data-cash-receipt={cashReceipt.cashReceipt}
            onClick={handleExport}
            value="Exportar"
          />
        ),
      };
    });
    setCashReceiptState({ cashReceipts: cashReceipts });
  };

  const handleExport = async (e) => {
    e.preventDefault();
    setRequestState({
      error: null,
    });
    try {
      const cashReceipt = e.target.getAttribute("data-cash-receipt");
      const response = await requests.reports.getCashReceiptReport(
        e.target.getAttribute("data-joint-id"),
        cashReceipt
      );
      downloadReport(response.data, `Recibo de Caja ${cashReceipt}`, "pdf");
    } catch (error) {
      console.log(error);
      setRequestState({
        error: CASH_RECEIPT_GENERATION_FAILED,
      });
    }
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <div className="square-form cash-receipt-grid">
              <h2 className="cash-receipt-title">Recibos de Caja</h2>
              <JointsBillingApartmentInput
                formik={formik}
                handleOnChange={handleTable}
              />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
            </div>
          </section>
          <ScafTable headers={headers} rows={cashReceiptState.cashReceipts} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    billingJoints: state.billingJoints,
    billingApartments: state.billingApartments,
  };
};

const mapDispatchToProps = {
  getBillingJointRequest,
  getBillingApartmentsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashReceipts);
