import React, { useState, useEffect, useId } from "react";
import { connect } from "react-redux";
import { requests } from "../../axios";
import {
  JOINTS_NOT_FOUND,
  USERDATA,
  REVERSE_REGISTER_FAILED,
  SWAL_QUESTION,
  SWAL_NOTIFICATION,
  REMOVE_QR_FAILED,
  APARTMENTS_NOT_FOUND,
  JOINT_INPUT_LABEL,
  JOINT_INPUT_DEFAULT_OPTION,
  APARTMENT_LABEL,
  APARTMENT_INPUT_DEFAULT_OPTION,
} from "../../types";
import { getAssemblyJointsRequest } from "../../actions";
import Loading from "../../components/Loading";
import "../../assets/styles/assembly/ReverseRegister.css";
import { getStorage } from "../../utils";
import { useFormik } from "formik";
import MultiSelect from "../../components/MultiSelect";

const ReverseRegister = (props) => {
  const id = useId();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [apartmentState, setApartmentState] = useState({
    registeredApartments: {},
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      if (!props.assemblyJoints.length) {
        const response = await requests.users.getUsersAssemblyJoints(
          props.user.userId || getStorage(USERDATA).userId
        );
        props.getAssemblyJointsRequest(response.data);
      }
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error.response.status);
      setRequestState({
        loading: false,
        error: JOINTS_NOT_FOUND,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      jointId: "",
      apartments: [],
    },
    onSubmit: async (values) => {
      try {
        setRequestState({
          ...requestState,
          error: null,
        });
        const swalAnswer = await SWAL_QUESTION.fire({
          icon: "warning",
          html: `<p>¿Está seguro de reversar los inmuebles ${values.apartments.map(
            (apartment) => `${apartment.value}`
          )}?</p>`,
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: "No",
        });
        if (swalAnswer.isConfirmed) {
          resetValues();
          await Promise.all(
            values.apartments.map(async (apartment) => {
              await requests.assemblyApartments.assignUserId({
                apartmentId: apartment.value,
                jointId: values.jointId,
                userId: null,
              });
              await requests.assembly.deleteRegisteredApartment({
                jointId: values.jointId,
                apartmentId: apartment.value,
                conceptId: 1,
              });
            })
          );
          await handleApartments();
          SWAL_NOTIFICATION.fire({
            icon: "success",
            title: "El reverso fue exitoso.",
          });
        }
      } catch (error) {
        resetValues();
        setRequestState({
          ...requestState,
          error: REVERSE_REGISTER_FAILED,
        });
      }
    },
  });

  const resetValues = () => {
    formik.setValues({
      ...formik.values,
      apartments: [],
    });
  };

  const handleApartments = async () => {
    const jointId = document.getElementById("joint").value;
    formik.setFieldValue("jointId", jointId);
    const response =
      jointId && (await requests.joints.getAssemblyApartments(jointId));
    setRegisteredApartments(response.data);
  };

  const setRegisteredApartments = (apartments) => {
    const registeredApartments = apartments
      .filter((apartment) => apartment.userId !== null)
      .map((apartment) => {
        return {
          value: apartment.apartmentId,
          label: `${apartment.apartmentId} - ${apartment.owner}`,
        };
      });
    setApartmentState({
      registeredApartments: registeredApartments,
    });
  };

  const options =
    props.assemblyJoints.length &&
    props.assemblyJoints.map((assembly, index) => {
      return (
        <option value={assembly.joint.jointId} key={`${id}-${index}`}>
          {assembly.joint.jointName}
        </option>
      );
    });

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <section className="userform" role="form">
            <form
              className="square-form reverse-register-grid"
              onSubmit={formik.handleSubmit}
            >
              <h2 className="reverse-register-title">Reversar Registro</h2>
              <h5 className="joint-label">{JOINT_INPUT_LABEL}</h5>
              <select
                className="joint-input userform-select"
                id="joint"
                name="joint"
                data-testid="jointInput"
                value={formik.values.jointId}
                onChange={handleApartments}
              >
                <option value="">{JOINT_INPUT_DEFAULT_OPTION}</option>
                {options}
              </select>
              <h5 className="apartments-label">{APARTMENT_LABEL}</h5>
              <MultiSelect
                className="apartments-input"
                id="apartments"
                name="apartments"
                placeholder={APARTMENT_INPUT_DEFAULT_OPTION}
                handleOnChange={(value) => {
                  formik.setFieldValue("apartments", value);
                }}
                value={formik.values.apartments}
                options={
                  apartmentState.registeredApartments.length
                    ? apartmentState.registeredApartments
                    : []
                }
              />
              {requestState.error && (
                <h6 className="bad-request error-message" role="alert">
                  {requestState.error}
                </h6>
              )}
              <input
                type="submit"
                className="btn btn-block scaf-accept-btn"
                value="Reversar"
              />
            </form>
          </section>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assemblyJoints: state.assemblyJoints,
  };
};

const mapDispatchToProps = {
  getAssemblyJointsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReverseRegister);
