import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { SlLogout } from "react-icons/sl";
import "../assets/styles/Header.css";
import SCAFLogo from "../assets/static/SCAF.png";
import NavBar from "./NavBar";
import { logInRequest, logOutRequest } from "../actions";
import { getStorage } from "../utils";
import { USERDATA } from "../types";

const Header = (props) => {
  useEffect(() => {
    fetchData();
  });

  const fetchData = () => {
    getStorage(USERDATA) && props.logInRequest(getStorage(USERDATA));
  };

  const isMobile = () => {
    const $mobileMenu = document.querySelector(".menu");
    const $navItems = document.querySelectorAll(".nav-item");
    if ($mobileMenu.classList.contains("displayed")) {
      $navItems.forEach((menuItem) => {
        menuItem.addEventListener("click", handleMobileMenu);
      });
    } else {
      $navItems.forEach((menuItem) => {
        menuItem.removeEventListener("click", handleMobileMenu);
      });
    }
  };

  const handleMobileMenu = () => {
    const $mobileMenuBtn = document.querySelector("#mobileMenuBtn");
    const $mobileMenu = document.querySelector(".menu");
    $mobileMenu.classList.toggle("displayed");
    isMobile();
    $mobileMenuBtn.classList.toggle("hide");
    $mobileMenuBtn.classList.toggle("show");
  };

  const getTypeNavBar = (navBarId) => {
    const navBar = {
      0: NavBar.Home,
      1: NavBar.Scaf,
      2: NavBar.Admin,
      3: NavBar.Owner,
      4: undefined,
      5: NavBar.Assembly,
      6: undefined,
    };
    return navBar[navBarId];
  };

  const handleLogOut = (e) => {
    sessionStorage.clear();
    localStorage.clear();
    props.logOutRequest({});
  };

  return (
    <header>
      <div className="header-container">
        <figure className="scaf-logo">
          <Link to={props.typeNavBar === 0 ? "/" : "/UserHome"}>
            <img
              src={SCAFLogo}
              title="SCAF"
              alt="Sistema de Gestion de Asambleas y Facturación"
              width="auto"
            />
          </Link>
        </figure>
        <nav className="menu" role="menu">
          <ol className="nav-list">{getTypeNavBar(props.typeNavBar)}</ol>
        </nav>
        <div className="user-session">
          {props.typeNavBar === 0 && (
            <Link className="btn scaf-accept-btn" to="/LogIn">
              Iniciar Sesión
            </Link>
          )}
          {props.typeNavBar !== 0 && props.typeNavBar !== 6 && (
            <Link to="/">
              <SlLogout
                className="logout-image"
                data-testid="logOut"
                onClick={handleLogOut}
              />
            </Link>
          )}
        </div>
        <div
          className="mobile-menu-btn show"
          data-testid="mobileMenuBtn"
          id="mobileMenuBtn"
          onClick={handleMobileMenu}
        ></div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    typeNavBar: state.typeNavBar,
  };
};

const mapDispatchToProps = {
  logInRequest,
  logOutRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
