import React, { useState, useEffect } from "react";
import {
  CONCEPT_DISABLE,
  TOKEN,
  APARTMENTS_NOT_FOUND,
  USERDATA,
} from "../../types";
import { logInRequest, getAssemblyApartmentsRequest } from "../../actions";
import Loading from "../../components/Loading";
import { useParams } from "react-router-dom";
import { auth, requests } from "../../axios";
import VoteForm from "../../components/VoteForm";
import { setStorage } from "../../utils";
import { connect } from "react-redux";

const VoteQR = (props) => {
  const params = useParams();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [voteState, setVoteState] = useState({
    data: null,
    isVoted: false,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      await authentication();
      (await isConceptPrepared())
        ? setRequestState({ error: null, loading: false })
        : setRequestState({ error: CONCEPT_DISABLE, loading: false });
    } catch (error) {
      console.log(error.response.status);
      setErrorMessage(error.response.request.responseURL);
    }
  };

  const authentication = async () => {
    // const response = await auth({
    //   username: params.username,
    //   password: params.username,
    // });
    // setStorage(TOKEN, `Bearer ${response.data.jwt}`);
    setStorage(USERDATA, JSON.stringify({ profileId: 6 }));
    props.logInRequest({ profileId: 6 });
  };

  const isConceptPrepared = async () => {
    const response = await requests.users.getUsersAssemblyApartments(
      params.username
    );
    const jointIds = getDistinctJoints(response.data);
    const activeConcepts = await getActiveConcepts(jointIds);
    setActiveConceptAndApartments(activeConcepts, response.data);
    return activeConcepts.filter((activeConcept) => activeConcept !== undefined)
      .length
      ? true
      : false;
  };

  const getDistinctJoints = (data) => {
    const joints = data.map((apartment) => {
      return [apartment.jointId, apartment.jointId];
    });
    return [...new Map(joints).values()];
  };

  const getActiveConcepts = async (jointIds) => {
    return Promise.all(
      await jointIds.map(async (jointId) => {
        try {
          const response = await requests.joints.getActiveConcept(jointId);
          return response.data;
        } catch (error) {
          error.response.status !== 404 &&
            setRequestState({
              ...requestState,
              error: CONCEPT_DISABLE,
            });
        }
      })
    );
  };

  const setActiveConceptAndApartments = (activeConcepts, apartments) => {
    activeConcepts
      .filter((activeConcept) => activeConcept !== undefined)
      .map((activeConcept) => {
        setVoteState({ data: activeConcept });
        const apartmentsData = apartments.filter(
          (apartment) => apartment.jointId === activeConcept.jointId
        );
        props.getAssemblyApartmentsRequest(apartmentsData);
        return null;
      });
  };

  const setErrorMessage = (responseUrl) => {
    const messageError =
      (responseUrl.includes("auth") && CONCEPT_DISABLE) ||
      (responseUrl.includes("apartments") && APARTMENTS_NOT_FOUND);
    setRequestState({
      loading: false,
      error: messageError,
    });
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <VoteForm
          voteState={voteState}
          setVoteState={setVoteState}
          requestState={requestState}
          setRequestState={setRequestState}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  logInRequest,
  getAssemblyApartmentsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VoteQR);
