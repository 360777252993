import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import "../../assets/styles/Register.css";
import {
  logInRequest,
  getAssemblyApartmentsRequest,
  getZoomMeetingRequest,
} from "../../actions";
import Loading from "../../components/Loading";
import { auth, requests } from "../../axios";
import { setStorage } from "../../utils";
import { TOKEN, USERDATA } from "../../types";

const Register = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [requestState, setRequestState] = useState({
    loading: true,
    error: null,
  });

  const [registerState, setRegisterState] = useState({
    isActive: 0,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    try {
      const response = await auth({
        username: params.user,
        password: params.password,
      });
      setStorage(TOKEN, `Bearer ${response.data.jwt}`);
      await getUser(params.user);
      const registerResponse =
        await requests.joints.getAssemblyConceptByJointIdAndConceptId({
          jointId: params.jointId,
          conceptId: 1,
        });
      setRegisterState({ isActive: registerResponse.data.active });
      props.getZoomMeetingRequest({
        zoomId: params.zoomId,
        zoomPassword: params.zoomPassword,
      });
      setRequestState({ ...requestState, loading: false });
    } catch (error) {
      console.log(error);
      setRequestState({ loading: false, error: error });
    }
  };

  const getUser = async (username) => {
    const response = await requests.users.getByUsername(username);
    delete response.data["password"];
    setStorage(USERDATA, JSON.stringify(response.data));
    props.logInRequest(response.data);
  };

  const handleRegister = async () => {
    try {
      setRequestState({ ...requestState, loading: true });
      const response = await requests.users.getUsersAssemblyApartments(
        props.user.userId
      );
      props.getAssemblyApartmentsRequest(response.data);
      response.data.map((apartment) =>
        requests.assembly.register({
          jointId: apartment.jointId,
          apartmentId: apartment.apartmentId,
          conceptId: 1,
          voteId: 1,
        })
      );
      setRequestState({ ...requestState, loading: false });
      navigate("/LaunchPage");
    } catch (error) {
      console.log(error);
      setRequestState({ loading: false, error: error });
    }
  };

  return (
    <>
      {requestState.loading ? (
        <Loading />
      ) : (
        <>
          <h1 className="welcome-message" data-testid="welcomeMessage">
            Bienvenido a la asamblea de copropietarios de <br />
            <span className="joint-name">{params.jointName}</span>.
          </h1>
          {registerState.isActive === 0 ? (
            <>
              <h2
                className="informational-message"
                data-testid="informationaleMessage"
              >
                El registro no ha sido activado.
              </h2>
              <Link
                className="btn scaf-accept-btn register-btn"
                to="/Tutorials"
              >
                Ver tutoriales
              </Link>
            </>
          ) : (
            <button
              className="btn scaf-accept-btn register-btn"
              onClick={handleRegister}
            >
              Registrar Asistencia
            </button>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = {
  logInRequest,
  getAssemblyApartmentsRequest,
  getZoomMeetingRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
