import React from "react";

import "../assets/styles/ServiceCard.css";

const ServiceCard = (props) => {
  return (
    <article className={`service-article ${props.className}`}>
      <figure className="article-image">
        <img
          src={props.image}
          srcSet={props.image2x}
          width="100%"
          alt={props.title}
          title={props.title}
        />
      </figure>
      <h3 className="title article-title">{props.title}</h3>
      {props.children}
    </article>
  );
};

export default ServiceCard;
